const es = {
  account: `Cuenta`,
  account_settings: `Configuración de la cuenta`,
  active: `Activo`,
  add: `Agregar`,
  add_user: `Agregar usuario`,
  admin: `Administrador`,
  all: `Todos`,
  announcement: `Anuncios`,
  announcement_management: `Administración de anuncio`,
  back: `Atrás`,
  bacteria_archaea_human: `Bacterias/Arqueas (humano)`,
  bacteria_archaea_plant: `Bacterias/Arqueas (planta)`,
  cancel: `Cancelar`,
  cannot_be_blank: `Este campo no puede dejarse en blanco.`,
  change_password: `Cambiar contraseña`,
  chart_mini_title: `Patógeno Mini`,
  chart_mini_guide: `El gráfico no está disponible para los proyectos Pathogen Mini. Haz clic en Más detalles para ver la tabla de datos.`,
  chart_title: `Las 10 principales especies`,
  company: 'Compañía',
  company_management: `Administración de la Compañía`,
  company_name: 'Nombre de la compañía',
  confirm: `Confirmar`,
  confirm_new_password: `Confirmar nueva contraseña`,
  contact_support: `Contactar soporte`,
  current_password: `Contraseña actual`,
  dashboard: `Tablero`,
  data_table: `Tabla de datos`,
  day: 'Día',
  download: `Descargar`,
  delete: `Eliminar`,
  detail: 'Detalle',
  detail_report: `Informe detallado`,
  drop_files: `Arrastre los archivos aquí o haga clic`,
  drop_files_2: `para navegar`,
  drop_files_3: `por su máquina`,
  edit_announcement: `Editar Anuncio`,
  edit_company: `Editar compañía`,
  edit_pathogen_url: `Editar URL del patógeno`,
  edit_project: `Editar proyecto`,
  edit_season: `Editar temporada`,
  edit_user: `Editar usuario`,
  email: `Correo electrónico`,
  email_address: `Dirección de correo electrónico`,
  failed_download: `Error al descargar el archivo.`,
  first_name: `Nombre`,
  forgot_password: `¿Olvidó su contraseña?`,
  fungi: `Hongos`,
  general_eukaryotes: `Eucariotas generales`,
  home: `Inicio`,
  inactive: `Inactivo`,
  invalid_email: `Dirección de correo electrónico no válida`,
  invalid_email2: `El correo electrónico debe ser una dirección de correo electrónico válida`,
  invalid_url: `URL inválida`,
  last_name: `Apellido`,
  location: `Ubicación`,
  login: `Iniciar sesión`,
  logout: `Cerrar sesión`,
  more_details: `Más detalles`,
  name: `Nombre`,
  new_announcement: `Nueva Anuncio`,
  new_company: `Nueva compañía`,
  new_password: `Nueva contraseña`,
  new_project: `Nuevo proyecto`,
  new_season: `Nueva temporada`,
  new_user: `Nuevo usuario`,
  no_data: `No hay datos`,
  no_resources: `No hay recursos`,
  original_file_upload: `Carga de archivo original`,
  password: `Contraseña`,
  password_match: `Las contraseñas deben coincidir`,
  password_rule: `La contraseña debe incluir 8 caracteres, al menos 1 letra minúscula, 1 letra mayúscula, un número y un símbolo.`,
  pathogen: `Patógeno`,
  pathogen_group: `Grupo de patógenos`,
  pathogen_group_s: `Grupos de patógeno(s)`,
  pathogen_groups: `Grupos de patógenos`,
  pathogen_management: `Administración de patógenos`,
  pathogen_s: `Patógeno(s)`,
  pathogen_subtitle: `El gráfico a continuación solo incluye las especies más relevantes para el cultivador, y los valores son el porcentaje del total de especies encontradas.`,
  pathogen_url: `URL del patógeno`,
  pathogens: `Patógenos`,
  project: `Proyecto`,
  projects: `Proyectos`,
  remove: `Remover`,
  remove_all: `Eliminar todo`,
  remove_profile_confirm_message: `¿Estás seguro/a de que quieres eliminar este perfil?`,
  require_confirm_password: `Se requiere confirmar la contraseña`,
  require_email: `Se requiere correo electrónico`,
  require_firstname: `El nombre es obligatorio`,
  require_lastname: `El apellido es obligatorio`,
  require_current_password: `Se requiere la contraseña actual`,
  require_new_password: `Se requiere una nueva contraseña`,
  require_password: `La contraseña es obligatoria`,
  reset_password: `Restablecer la contraseña`,
  resources: `Recursos`,
  sample: `muestra`,
  sampling: `Muestreo`,
  save: `Guardar`,
  save_changes: `Guardar cambios`,
  search: `Buscar...`,
  season: `Temporada`,
  seasons: `Temporadas`,
  select_files: `Seleccionar archivos`,
  select_project_and_season_title: `Proyecto/Temporada`,
  select_project_and_season_subtitle: `Seleccione proyecto y temporada para ver detalles`,
  set_up_password: `Establecer contraseña`,
  settings: `Configuraciones`,
  status: `Estado`,
  submit: `Enviar`,
  submit_policy: `Al enviar, Acepto los`,
  submit_policy_2: `términos y condiciones`,
  submit_policy_3: `y las`,
  submit_policy_4: `Política de privacidad.`,
  submit_sample: `Enviar muestra`,
  switch_operation: `Cambiar operación`,
  select_operation: `Seleccionar operación`,
  slideshow_management: `Gestión de Presentación de Diapositivas`,
  slideshow_resources: `Recursos de Presentación de Diapositivas`,
  there_are_no_data: `No hay datos`,
  unable: `No es posible cerrar sesión!`,
  upload: `Cargar formulario de envío de muestra`,
  upload_files: `Cargar archivos`,
  url_status: `Estado del URL`,
  user: `Usuario`,
  user_management: `Administración de usuarios`,
  users: `Usuarios`,
  view: `Ver`,
  view_detail: `Ver detalle`,
  view_report: `Ver Informe`,
  welcome: `Bienvenido`,
  text_contact_support: `¿Tiene problemas para acceder al portal? Por favor contacte con el`,
  text_contact_support_part2: `equipo de soporte`,
  text_contact_support_part3: `para obtener ayuda adicional.`,
  type: `Tipo`,
  type_alert: `Alerta`,
  type_info: `Información`,
  powered_by: `Desarrollado por UBIX Innovations Inc.`,
  linked: `Vinculado`,
  unlinked: `No vinculado`,

  // message
  message: {
    save: `Guardado exitosamente`,
    sampling_submit: `Solicitud de muestreo enviada exitosamente`,
  },

  // sort by page
  page: {
    login: {
      title: `Bienvenido al Portal de Cultivadores`,
      reset_message: `Hemos enviado el enlace de restablecimiento de contraseña a`,
      reset_message_part2: `Si no encuentra el correo electrónico, por favor revise su carpeta de correo no deseado o asegúrese de que su dirección de correo electrónico esté registrada.`,
    },
    forgot_password: {
      title: `¿Olvidó su contraseña?`,
      description: `Ingrese la dirección de correo electrónico con la que se registró y le enviaremos el enlace para restablecer la contraseña por correo electrónico.`,
    },
    new_password: {
      title: `¡Nos complace tenerte a bordo!`,
      reset_title: `Restablezca su contraseña`,
      desc: `Por favor configure su contraseña para completar el registro.`,
      invalid_message: `Enlace de restablecimiento de contraseña no válido. Por favor, intente restablecer su contraseña de nuevo`,
      set_password: `Establecer contraseña`,
    },
    verify_code: {
      verify_code: `Verificar código`,
      title: `Autenticación de Dos Factores`,
      unregistered_info: `Para confirmar que la aplicación está configurada correctamente, ingrese el código de seguridad que aparece en su teléfono.`,
      registered_info: `Ingrese el código de token de Google Authenticator o Microsoft Authenticator.`,
      google_auth: `Google Authenticator`,
      microsoft_auth: `Microsoft Authenticator`,
      or: `o`,
      on_your_phone: `en su teléfono`,
      qr_info: `Abra la aplicación y toque comenzar configuración`,
      qr_info_part2: `Escanee el código QR`,
      require_code: `Se requiere un código`,
      invalid_code: `Código inválido`,
    },

    user: {
      select_operation: {
        title: `Seleccione su operación`,
        table_head: `Mis operaciones`,
        no_company: `No se te ha asignado ninguna operación.`,
      },
      dashboard: {
        page_title: `Panel principal`,
        title: `Bienvenido de nuevo`,
        subtitle: `¿Está listo para enviar su próxima muestra?`,
      },
      no_project_dashboard: {
        title: `No hay proyectos aún`,
        subtitle: `La información del proyecto aparecerá una vez que se envíen y analicen las muestras.`,
        description: `¡Bienvenido! Estamos encantados de tenerte a bordo.`,
        submit_sample_title: `¡Envía tu primera muestra!`,
        submit_sample_desc: `Podemos ayudar a identificar patógenos de plantas y patógenos humanos en su sistema hidropónico y en las superficies de las raíces de los cultivos.`,
      },
      detail_report: {
        reports: `Informes`,
        type: `Tipo`,
        created_date: `Fecha de creación`,
        link: `Enlace`,
        there_are_no_reports: `No hay informes`,
      },
      sampling: {
        add_sampling_data: `Agregar datos de muestreo`,
        location_name: `Nombre del lugar`,
        sampling_type: `Tipo de muestreo`,
        sampling_from: `Muestreo desde`,
        temp_sample: `Muestra de temperatura`,
        last_updated: `Última actualización`,
        additional_info: `Información adicional`,
        temp_atmosphere: `Temperatura ambiente`,
        save_sampling_form: `Guardar formulario de muestreo`,
      },
      submit_sample: {
        title: `Envía tus muestras`,
        description: `Descarga el formulario y completa la información. Una vez completado, sube el formulario de envío de muestra completo. Para múltiples muestras, utilice un solo formulario. Imprima el formulario de envío de muestra para enviarlo con las muestras.`,
        note_span: `Nota:`,
        note: `la información que recopilamos en las granjas se mantiene confidencial. Cualquier metadato recopilado y datos de secuenciación generados se utilizan para mejorar nuestra base de datos de patógenos internos y para ayudar a servir mejor a los cultivadores.`,
        download_button: `Descargar el formulario de envío de muestra`,
        learn_more_button: `Obtén más información sobre el envío de muestras`,
        courier: `Mensajero*`,
        trackingID: `ID/URL de seguimiento*`,
        textarea: `¿Cuándo planea enviar sus muestras?`,
        alert: `¡La muestra se ha enviado correctamente!`,
        require_courier: `Se requiere el servicio de mensajería`,
        require_tracking: `Se requiere información de seguimiento`,
      },
    },

    admin: {
      company_management: {
        manage_users: `Gestionar usuarios`,
        manage_projects: `Gestionar proyectos`,
        phone_number: `Número de teléfono`,
        country: `País`,
        street: `Calle`,
        state_region: `Estado/Región`,
        city: `Ciudad`,
        zip_code: `Código postal`,
        about: `Acerca de`,
      },
      manage_users: {
        edit_profile: `Editar perfil`,
        remove_profile: `Eliminar perfil`,
        email_address: `Dirección de correo electrónico`,
        confirm_password: `Confirmar contraseña`,
        send_invitation: `Enviar invitación`,
      },
      manage_projects: {
        project_name: `Nombre del proyecto`,
        service_type: `Tipo de Servicio`,
        created_at: `Creado An`,
        manage_seasons: `Gestionar temporadas`,
        require_project_name: `El nombre del proyecto es obligatorio`,
      },
      manage_seasons: {
        season_name: `Nombre de la temporada`,
        start_date: `Fecha de inicio`,
        require_date: `La fecha de inicio es obligatoria`,
        require_season_name: `El nombre de la temporada es obligatorio`,
      },
      import_data: {
        import_report_data: `Importar Datos del Informe`,
        import_pathogen_data: `Importar Datos del Patógeno`,
        import_pdf_data: `Importar Datos PDF`,
        import_html_data: `Importar Datos HTML`,
        guide_message_to_admin: `Mensaje de Guía al Administrador`,
        guide_description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ornare non nunc vel finibus. Curabitur eget libero orci. Mauris bibendum, arcu ac lobortis congue, ligula ante lacinia purus, faucibus finibus nisl diam non tellus`,
        invalid_type: `Tipo de Datos Inválido`,
        require_file: `Se requiere archivo`,
        sample_id: `ID de muestra`,
        date_time: `Fecha y hora`,
        sample_type: `Tipo de muestra`,
        sample_from: `Procedencia de la muestra`,
        light: `Luz`,
      },
      pdf_html: {
        manage_pdf_html: `Gestionar PDF/HTML`,
        type: `Tipo`,
        created_date: `Fecha de creación`,
        inactive_file: `Archivo inactivo`,
        active_file: `Archivo activo`,
      },
      pathogens_detail: {
        user_pathogen_detail: `Detalles de patógenos de usuario`,
        pathogens_detail: `Detalle de Patógenos`,
        pathogen_mini: `Patógeno Mini`,
        send_notifications: `Enviar Notificaciones`,
        sampling_detail: `Detalle de muestreo`,
      },
      pathogen_management: {
        pathogen_name: `Nombre del Patógeno`,
        edit_url: `Editar URL`,
        url: `URL`,
      },
      announcement_management: {
        title: `Título`,
        description: `Descripción`,
        start_date: `Fecha de inicio`,
        end_date: `Fecha de finalización`,
        require_start_date: `Fecha de inicio requerida`,
        require_end_date: `Fecha de finalización requerida`,
        require_title: 'Título es requerido',
        require_desc: 'Descripción es requerida',
      },
      slideshow_management: {
        title_desc: `Titulo y Breve Descripci`,
        title: `Título`,
        group: `Grupo`,
        short_description: `Breve Descripción`,
        link_url: `URL del Enlace`,
        image: `Imagen`,
        slideshow_image: `Imagen de Presentación de Diapositivas`,
        slide_order: `Orden de las Diapositivas`,
        edit_slideshow: `Editar Presentación de Diapositivas`,
        new_slideshow: `Nueva Presentación de Diapositivas`,
        order_number_type_error: `El número de pedido debe ser un número`,
        click_to_view_url: `Click para ver la URL`,
      },
    },

    error: {
      forbidden: `403 Prohibido`,
      no_permission: `Sin permiso`,
      forbidden_info: `La página a la que intentas acceder tiene acceso restringido.`,
      forbidden_info_part2: `Por favor, consulta con tu administrador de sistema.`,
      go_to_home: `Ir a Inicio`,
      page_not_found: `Página no encontrada`,
      notfound_info: `Lo siento, no pudimos encontrar la página que estás buscando.`,
      notfound_info_part2: `Puede que hayas escrito la dirección incorrectamente, o el enlace al que has hecho clic esté roto.`,
      back_to_home: `Volver a Inicio`,
      internal_server_error: `Error interno del servidor 500`,
      server_error_info: `Ocurrió un error, por favor intenta de nuevo más tarde.`,
      permission_denied: `Permiso denegado`,
    },
  },
};

export default es;

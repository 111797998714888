// ----------------------------------------------------------------------
function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_ADMIN = '/admin';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_PATHOGENS = '/pathogens';
const ROOTS_SAMPLING = '/sampling';
const ROOTS_USER = '/user';
const ROOTS_API_SERVER = '/api';
const ROOTS_SUBMIT_SAMPLE = '/submit-sample';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/',
  register: '/register',
  verify: '/verify',
  selectOperation: '/select-operation',
};

export const PATH_ACCOUNTS = {
  resetPassword: '/reset-password',
  newPassword: '/new-password',
  invitation: '/invitation',
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  companyManagement: {
    root: path(ROOTS_ADMIN, '/company-management'),
    company: path(ROOTS_ADMIN, '/company-management/overview'),
    newCompany: path(ROOTS_ADMIN, `/company-management/new-company`),
    editCompany: (company) => path(ROOTS_ADMIN, `/company-management/company/${company}/edit-company`),
    management: (company) => path(ROOTS_ADMIN, `/company-management/company/${company}/user-management`),
    projects: (company) => path(ROOTS_ADMIN, `/company-management/company/${company}/projects`),
    newProject: (company) => path(ROOTS_ADMIN, `/company-management/company/${company}/new-project`),
    editProject: (company, project) =>
      path(ROOTS_ADMIN, `/company-management/company/${company}/projects/${project}/edit-project`),
    seasons: (company, project) =>
      path(ROOTS_ADMIN, `/company-management/company/${company}/projects/${project}/seasons`),
    newSeason: (company, project) =>
      path(ROOTS_ADMIN, `/company-management/company/${company}/projects/${project}/new-season`),
    editSeason: (company, project, season) =>
      path(ROOTS_ADMIN, `/company-management/company/${company}/projects/${project}/seasons/${season}/edit-season`),
    importData: (company, project, season, type) =>
      path(ROOTS_ADMIN, `/company-management/company/${company}/projects/${project}/seasons/${season}/import/${type}`),
    editPdfHtml: (company, project, season) =>
      path(ROOTS_ADMIN, `/company-management/company/${company}/projects/${project}/seasons/${season}/pdf-html`),
    pathogensDetail: (company) => path(ROOTS_ADMIN, `/company-management/pathogens-detail/company/${company}`),
    pathogenDetailHtml: (company, project, season, id) =>
      path(
        ROOTS_ADMIN,
        `/company-management/pathogens-detail/company/${company}/projects/${project}/seasons/${season}/html/${id}`
      ),
    samplingDetail: (company, projectId, seasonId, day) =>
      path(
        ROOTS_ADMIN,
        `/company-management/pathogens-detail/company/${company}/sampling/projects/${projectId}/seasons/${seasonId}/dailyInfo/${day}`
      ),
  },
  userManagement: {
    root: path(ROOTS_ADMIN, '/user-management'),
    user: path(ROOTS_ADMIN, '/user-management/overview'),
    newUser: path(ROOTS_ADMIN, '/user-management/new-user'),
    editUser: (account) => path(ROOTS_ADMIN, `/user-management/accounts/${account}/edit-user`),
  },
  pathogenManagement: {
    root: path(ROOTS_ADMIN, '/pathogen-management'),
    pathogensUrlList: path(ROOTS_ADMIN, '/pathogen-management/overview'),
    editPathogensUrl: (pathogen) => path(ROOTS_ADMIN, `/pathogen-management/pathogens/${pathogen}/edit-url`),
  },
  announcementManagement: {
    root: path(ROOTS_ADMIN, '/announcement-management'),
    announcementList: path(ROOTS_ADMIN, '/announcement-management/overview'),
    newAnnouncement: path(ROOTS_ADMIN, '/announcement-management/new'),
    editAnnouncement: (announcement) => path(ROOTS_ADMIN, `/announcement-management/${announcement}/edit`),
  },
  slideshowManagement: {
    root: path(ROOTS_ADMIN, '/slideshow-management'),
    slideshow: path(ROOTS_ADMIN, '/slideshow-management/overview'),
    newSlideshow: path(ROOTS_ADMIN, '/slideshow-management/new'),
    editSlideshow: (slideshow) => path(ROOTS_ADMIN, `/slideshow-management/${slideshow}/edit`),
  },
  originalFileUpload: {
    upload: path(ROOTS_ADMIN, '/original-file/upload'),
  },
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    overview: path(ROOTS_DASHBOARD, '/overview'),
  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
};

export const PATH_PATHOGENS = {
  root: ROOTS_PATHOGENS,
  general: {
    overview: path(ROOTS_PATHOGENS, '/overview'),
    pathogensDetail: (projectId, seasonId) => path(ROOTS_PATHOGENS, `/projects/${projectId}/seasons/${seasonId}`),
    pathogenDetailHtml: (projectId, seasonId, id) =>
      path(ROOTS_PATHOGENS, `/projects/${projectId}/seasons/${seasonId}/html/${id}`),
  },
};

export const PATH_SAMPLING = {
  root: ROOTS_SAMPLING,
  general: {
    overview: path(ROOTS_SAMPLING, '/overview'),
    samplingWithIDs: (projectId, seasonId) => path(ROOTS_SAMPLING, `/projects/${projectId}/seasons/${seasonId}`),
    edit: (projectId, seasonId, day) =>
      path(ROOTS_SAMPLING, `/projects/${projectId}/seasons/${seasonId}/dailyInfo/${day}`),
  },
};

export const PATH_USER = {
  root: ROOTS_USER,
  general: {
    new: path(ROOTS_USER, '/new'),
    list: path(ROOTS_USER, '/list'),
    cards: path(ROOTS_USER, '/cards'),
    profile: path(ROOTS_USER, '/profile'),
    account: path(ROOTS_USER, '/account'),
    edit: (name) => path(ROOTS_USER, `/${name}/edit`),
  },
};

export const PATH_SUBMIT_SAMPLE = {
  root: ROOTS_SUBMIT_SAMPLE,
  general: {
    overview: path(ROOTS_SUBMIT_SAMPLE, '/overview'),
  },
};

export const PATH_SERVER_API = {
  root: ROOTS_API_SERVER,
  auth: {
    signup: path(ROOTS_API_SERVER, '/auth/signup'),
    login: path(ROOTS_API_SERVER, '/auth/login'),
    otpVerify: path(ROOTS_API_SERVER, '/auth/otp/verify'),
    refresh: path(ROOTS_API_SERVER, '/auth/refresh'),
    logout: path(ROOTS_API_SERVER, '/auth/logout'),
    resetPassword: path(ROOTS_API_SERVER, '/auth/reset-password'),
    newPassword: path(ROOTS_API_SERVER, '/auth/new-password'),
    multiCompany: path(ROOTS_API_SERVER, '/multi-company'),
  },
  accounts: {
    resetPassword: path(ROOTS_API_SERVER, '/accounts/reset-password'),
    newPassword: path(ROOTS_API_SERVER, '/accounts/new-password'),
    allAccounts: path(ROOTS_API_SERVER, '/accounts'),
    accountInfo: (userId) => path(ROOTS_API_SERVER, `/accounts/${userId}`),
    accountProfile: (userId) => path(ROOTS_API_SERVER, `/accounts/${userId}/profile`),
    changePassword: (userId) => path(ROOTS_API_SERVER, `/accounts/${userId}/change-password`),
    verifyToken: path(ROOTS_API_SERVER, '/accounts/verify-token'),
  },
  support: {
    supportEmail: path(ROOTS_API_SERVER, '/support/email'),
    announcement: path(ROOTS_API_SERVER, '/support/announcement'),
  },
  dashboard: {
    slideShow: path(ROOTS_API_SERVER, '/dashboard/slide-shows'),
  },
  pathogen: {
    projects: path(ROOTS_API_SERVER, '/pathogen/project-info'),
    seasons: {
      chart: path(ROOTS_API_SERVER, '/pathogen/season-chart'),
      sameDayChart: path(ROOTS_API_SERVER, '/pathogen/sd-season-chart'),
      miniChart: path(ROOTS_API_SERVER, '/pathogen/mini-season-chart'),
      table: path(ROOTS_API_SERVER, '/pathogen/season-table'),
      sameDayTable: path(ROOTS_API_SERVER, '/pathogen/sd-season-table'),
      miniTable: path(ROOTS_API_SERVER, '/pathogen/mini-season-table'),
    },
    daily: {
      chart: path(ROOTS_API_SERVER, '/pathogen/daily-chart'),
      table: path(ROOTS_API_SERVER, '/pathogen/daily-table'),
      miniTable: path(ROOTS_API_SERVER, '/pathogen/mini-daily-table'),
      info: (projectId, seasonId, dailyInfoId) =>
        path(
          ROOTS_API_SERVER,
          `/pathogen/daily-info/projects/${projectId}/seasons/${seasonId}/daily-info/${dailyInfoId}`
        ),
      infoTable: path(ROOTS_API_SERVER, '/pathogen/daily-info-table'),
      infoData: (dailyInfoId) => path(ROOTS_API_SERVER, `/pathogen/daily-data/daily-info/${dailyInfoId}`),
      post: path(ROOTS_API_SERVER, '/pathogen/daily-data'),
    },
    samplingResultTable: (projectId, seasonId) =>
      path(ROOTS_API_SERVER, `/pathogen/sampling-result-table/projects/${projectId}/seasons/${seasonId}`),
    samplingResultDownload: (id) => path(ROOTS_API_SERVER, `/pathogen/sampling-result/${id}/download`),
    samplingRequest: path(ROOTS_API_SERVER, '/submit/sampling-request'),
    widgets: path(ROOTS_API_SERVER, '/widgets'),
  },
  admin: {
    common: {
      getAllLanguages: path(ROOTS_API_SERVER, `/admin/common/language`),
    },
    companies: {
      getAllCompanies: (target) => path(ROOTS_API_SERVER, `/admin/${target}`),
      getCompanyProfile: (companyId) => path(ROOTS_API_SERVER, `/admin/companies/${companyId}/profile`),
      createAndUpdateCompanyProfile: path(ROOTS_API_SERVER, '/admin/companies/profile'),
      addUserToCompany: path(ROOTS_API_SERVER, '/admin/companies/accounts'),
      deleteUserToCompany: path(ROOTS_API_SERVER, '/admin/companies/accounts'),
    },
    accounts: {
      getAllAccounts: (companyId, target) => path(ROOTS_API_SERVER, `/admin/accounts/companies/${companyId}/${target}`),
      invitation: path(ROOTS_API_SERVER, '/admin/accounts/invitation'),
      getAllUsers: path(ROOTS_API_SERVER, '/admin/accounts/all'),
      getProfile: (accountId) => path(ROOTS_API_SERVER, `/admin/accounts/${accountId}/profile`),
      newUser: path(ROOTS_API_SERVER, '/admin/accounts/create-profile'),
      editUser: path(ROOTS_API_SERVER, '/admin/accounts/profile'),
    },
    pathogens: {
      projectInfoByCompany: path(ROOTS_API_SERVER, '/admin/project/project-info'),
      seasonChartByCompany: path(ROOTS_API_SERVER, '/admin/project/season-chart'),
      seasonTableByCompany: path(ROOTS_API_SERVER, '/admin/project/season-table'),
      sameDaySeasonTableByCompany: path(ROOTS_API_SERVER, '/admin/project/sd-season-table'),
      sameDaySeasonChartByCompany: path(ROOTS_API_SERVER, '/admin/project/sd-season-chart'),
      miniSeasonTableByCompany: path(ROOTS_API_SERVER, '/admin/project/mini-season-table'),
      dailyInfoTableByCompany: path(ROOTS_API_SERVER, '/admin/project/daily-info-table'),
      dailyTableByCompany: path(ROOTS_API_SERVER, '/admin/project/daily-table'),
      miniDailyTableByCompany: path(ROOTS_API_SERVER, '/admin/project/mini-daily-table'),
      dailyChartByCompany: path(ROOTS_API_SERVER, '/admin/project/daily-chart'),
      dailyInfoByCompany: (dailyInfoId) =>
        path(ROOTS_API_SERVER, `/admin/project/daily-data/daily-info/${dailyInfoId}`),
      dailyInfoItemByCompany: (projectId, seasonId, dailyInfoId) =>
        path(
          ROOTS_API_SERVER,
          `/admin/project/daily-info/projects/${projectId}/seasons/${seasonId}/daily-info/${dailyInfoId}`
        ),
      samplingResultTableByCompany: path(ROOTS_API_SERVER, '/admin/project/sampling-result-table'),

      projects: (companyId) => path(ROOTS_API_SERVER, `/admin/project/company/${companyId}/projects`),
      projectsByProjectId: (companyId, projectId) =>
        path(ROOTS_API_SERVER, `/admin/project/company/${companyId}/projects/${projectId}`),
      addProjects: path(ROOTS_API_SERVER, `/admin/project/projects`),
      projectsInfo: path(ROOTS_API_SERVER, `/admin/project/projects`),
      seasonsInfo: path(ROOTS_API_SERVER, `/admin/project/seasons`),

      seasons: (companyId, projectId) =>
        path(ROOTS_API_SERVER, `/admin/project/company/${companyId}/projects/${projectId}/seasons`),
      seasonsBySeasonId: (companyId, projectId, seasonId) =>
        path(ROOTS_API_SERVER, `/admin/project/company/${companyId}/projects/${projectId}/seasons/${seasonId}`),
      addSeasons: path(ROOTS_API_SERVER, `/admin/project/seasons`),

      postReportData: path(ROOTS_API_SERVER, '/admin/project/import/daily-info-file'),
      postSamplingData: path(ROOTS_API_SERVER, '/admin/project/import/sampling-data-file'),
      upload: path(ROOTS_API_SERVER, `/admin/project/sampling-result/upload`),
      nameInfo: (companyId, projectId, seasonId) =>
        path(
          ROOTS_API_SERVER,
          `/admin/project/company/${companyId}/projects/${projectId}/seasons/${seasonId}/name-info`
        ),
      dataImportNotificationStatus: path(ROOTS_API_SERVER, '/admin/project/data-imported-notification/status'),
      dataImportNotification: path(ROOTS_API_SERVER, '/admin/project/data-imported-notification'),
      pdfHtmlInfo: (companyId, projectId, seasonId) =>
        path(
          ROOTS_API_SERVER,
          `/admin/project/pdf-html-info/company/${companyId}/projects/${projectId}/seasons/${seasonId}`
        ),
      updatePdfHtmlInfo: path(ROOTS_API_SERVER, `/admin/project/pdf-html-info`),
    },
    pathogensUrl: {
      getAllPathogensUrlList: path(ROOTS_API_SERVER, '/admin/pathogens'),
      getPathogensUrl: (pathogenId) => path(ROOTS_API_SERVER, `/admin/pathogens/${pathogenId}`),
      editPathogensUrl: path(ROOTS_API_SERVER, '/admin/pathogens'),
    },
    announcement: {
      getAllAnnouncements: path(ROOTS_API_SERVER, '/admin/support/announcement'),
      getAnnouncement: (announcementId) => path(ROOTS_API_SERVER, `/admin/support/announcement/${announcementId}`),
      createAndUpdateAnnouncement: path(ROOTS_API_SERVER, '/admin/support/announcement'),
    },
    slideshow: path(ROOTS_API_SERVER, '/admin/support/slide-show'),
  },
  uploader: {
    originalFile: path(ROOTS_API_SERVER, '/admin/project/import/original-file'),
  },
};

export const getPathogenAPIPathByRole = (isAdmin, pathName) => {
  const ADMIN = {
    projects: PATH_SERVER_API.admin.pathogens.projectInfoByCompany,
    seasonChart: PATH_SERVER_API.admin.pathogens.seasonChartByCompany,
    sameDaySeasonChart: PATH_SERVER_API.admin.pathogens.sameDaySeasonChartByCompany,
    seasonTable: PATH_SERVER_API.admin.pathogens.seasonTableByCompany,
    miniSeasonTable: PATH_SERVER_API.admin.pathogens.miniSeasonTableByCompany,
    sameDaySeasonTable: PATH_SERVER_API.admin.pathogens.sameDaySeasonTableByCompany,
    dailyInfoTable: PATH_SERVER_API.admin.pathogens.dailyInfoTableByCompany,
    dailyChart: PATH_SERVER_API.admin.pathogens.dailyChartByCompany,
    dailyInfoData: (dailyInfoId) => PATH_SERVER_API.admin.pathogens.dailyInfoByCompany(dailyInfoId),
    dailyInfoItem: (projectId, seasonId, dailyInfoId) =>
      PATH_SERVER_API.admin.pathogens.dailyInfoItemByCompany(projectId, seasonId, dailyInfoId),
    dailyTable: PATH_SERVER_API.admin.pathogens.dailyTableByCompany,
    miniDailyTable: PATH_SERVER_API.admin.pathogens.miniDailyTableByCompany,
    samplingResult: PATH_SERVER_API.admin.pathogens.samplingResultTableByCompany,
  };

  const USER = {
    projects: PATH_SERVER_API.pathogen.projects,
    seasonChart: PATH_SERVER_API.pathogen.seasons.chart,
    sameDaySeasonChart: PATH_SERVER_API.pathogen.seasons.sameDayChart,
    seasonTable: PATH_SERVER_API.pathogen.seasons.table,
    miniSeasonTable: PATH_SERVER_API.pathogen.seasons.miniTable,
    sameDaySeasonTable: PATH_SERVER_API.pathogen.seasons.sameDayTable,
    dailyInfoTable: PATH_SERVER_API.pathogen.daily.infoTable,
    dailyChart: PATH_SERVER_API.pathogen.daily.chart,
    dailyInfoData: (dailyInfoId) => PATH_SERVER_API.pathogen.daily.infoData(dailyInfoId),
    dailyInfoItem: (projectId, seasonId, dailyInfoId) =>
      PATH_SERVER_API.pathogen.daily.info(projectId, seasonId, dailyInfoId),
    dailyTable: PATH_SERVER_API.pathogen.daily.table,
    miniDailyTable: PATH_SERVER_API.pathogen.daily.miniTable,
    samplingResult: (projectId, seasonId) => PATH_SERVER_API.pathogen.samplingResultTable(projectId, seasonId),
  };

  return isAdmin ? ADMIN[pathName] : USER[pathName];
};

export const PUBLIC_REQUEST_URL = [PATH_SERVER_API.support.supportEmail, PATH_SERVER_API.support.announcement];

// Common Info API Call should be blocked on ROOT URL
// ROOT URL is a bridge to handle navigation
export const ROOT_URL_LIST = [
  PATH_DASHBOARD.root,
  PATH_ADMIN.root,
  PATH_ADMIN.companyManagement.root,
  PATH_ADMIN.userManagement.root,
  PATH_ADMIN.pathogenManagement.root,
  PATH_ADMIN.announcementManagement.root,
  PATH_ADMIN.slideshowManagement.root,
];

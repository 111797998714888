import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA4 from 'react-ga4';

const ReactGARouteChangeTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (window.location.href.includes('localhost')) {
      return;
    }

    const key = process.env.REACT_APP_ANALYTICS_TRACKING_ID;
    if (key === null || key === '') return;

    console.log(`GA KEY : ${key.substring(1, 4)}`);
    ReactGA4.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_ID);
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA4.send({ hitType: 'pageview', page: location.pathname + location.search });
    }
  }, [initialized, location]);
};

export default ReactGARouteChangeTracker;

import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { setSession, getOtpVerifiedInfo, getInfoFromToken } from '../utils/jwt';
import { PATH_SERVER_API } from '../routes/paths';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  otpVerified: false,
  otpEnabled: false,
  otpRegistered: false,
  otpQRCodeImage: null,
  otpToken: null,
  supportEmail: null,
  multiCompany: null,
  announcement: [],
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, otpVerified, supportEmail, multiCompany, announcement } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      otpVerified,
      supportEmail,
      multiCompany,
      announcement,
    };
  },
  LOGIN: (state, action) => {
    const { user, otpVerified, otpEnabled, otpRegistered, otpQRCodeImage, otpToken, multiCompany } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      otpVerified,
      otpEnabled,
      otpRegistered,
      otpQRCodeImage,
      otpToken,
      multiCompany,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    otpVerified: false,
  }),
  REGISTER: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  VERIFY: (state, action) => {
    const { otpVerified, otpEnabled, otpRegistered, otpQRCodeImage, otpToken, multiCompany } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      otpVerified,
      otpEnabled,
      otpRegistered,
      otpQRCodeImage,
      otpToken,
      multiCompany,
    };
  },
  REFRESHUSERINFO: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
  SETALLCOMMONINFO: (state, action) => {
    const { user, supportEmail, announcement, otpEnabled, otpVerified, multiCompany } = action.payload;
    return {
      ...state,
      user,
      otpEnabled,
      otpVerified,
      multiCompany,
      supportEmail,
      announcement,
    };
  },
  SETCOMMONINFO: (state, action) => {
    const { supportEmail, announcement } = action.payload;
    return {
      ...state,
      supportEmail,
      announcement,
    };
  },
};
const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verify: () => Promise.resolve(),
  refreshUserInfo: () => Promise.resolve(),
  getCommonInfo: () => Promise.resolve(),
  getCompanyList: () => Promise.resolve(),
  setCompany: () => Promise.resolve(),
  emptyCompany: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const otpVerifiedInfo = getOtpVerifiedInfo(accessToken);

        if (accessToken) {
          const userId = getInfoFromToken('userId');
          let user;
          let otpEnabledInfo = false;
          let multiCompanyInfo = null;

          if (userId) {
            const response = await axios.get(PATH_SERVER_API.accounts.accountInfo(userId));
            const { companyName, email, firstName, lastName, otpEnabled, role, roleDisplayName, multiCompany } =
              response.data;

            user = {
              displayName: `${firstName} ${lastName}`,
              firstName,
              companyName,
              email,
              role,
              roleDisplayName,
            };

            otpEnabledInfo = otpEnabled;
            multiCompanyInfo = multiCompany;
          }

          dispatch({
            type: 'INITIALIZE',
            payload: {
              user,
              isAuthenticated: true,
              otpVerified: otpVerifiedInfo,
              otpEnabled: otpEnabledInfo,
              multiCompany: multiCompanyInfo,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              otpVerified: otpVerifiedInfo,
              otpEnabled: false,
              user: null,
              multiCompany: null,
            },
          });
        }
      } catch (err) {
        // if exists access token remove it
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) localStorage.removeItem('accessToken');
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            multiCompany: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (userEmail, userPassword) => {
    const response = await axios.post(PATH_SERVER_API.auth.login, {
      email: userEmail,
      password: userPassword,
    });

    const {
      accessToken,
      otpEnabled,
      otpRegistered,
      otpQRCodeImage,
      otpToken,
      email,
      role,
      companyName,
      roleDisplayName,
      firstName,
      lastName,
      multiCompany,
    } = response.data;
    setSession(accessToken);

    const user = {
      displayName: `${firstName} ${lastName}`,
      firstName,
      companyName,
      email,
      role,
      roleDisplayName,
    };

    const otpVerifiedInfo = getOtpVerifiedInfo(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        otpVerified: otpVerifiedInfo,
        otpEnabled,
        otpRegistered,
        otpQRCodeImage,
        otpToken,
        multiCompany,
      },
    });
  };

  const verify = async (code) => {
    const response = await axios.post(PATH_SERVER_API.auth.otpVerify, {
      code,
    });

    const { accessToken, otpEnabled, otpRegistered, otpQRCodeImage, otpToken, multiCompany } = response.data;
    setSession(accessToken);
    const otpVerifiedInfo = getOtpVerifiedInfo(accessToken);

    dispatch({
      type: 'VERIFY',
      payload: {
        otpVerified: otpVerifiedInfo,
        otpEnabled,
        otpRegistered,
        otpQRCodeImage,
        otpToken,
        multiCompany,
      },
    });
  };

  const register = async (email, password, firstName, lastName) => {
    await axios.post(PATH_SERVER_API.auth.signup, {
      email,
      password,
      firstName,
      lastName,
    });

    dispatch({
      type: 'REGISTER',
      payload: {},
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const refreshUserInfo = async () => {
    const userId = getInfoFromToken('userId');
    const response = await axios.get(PATH_SERVER_API.accounts.accountInfo(userId));
    const { companyName, firstName, lastName, email, role, roleDisplayName } = response.data;

    const user = {
      displayName: `${firstName} ${lastName}`,
      firstName,
      companyName,
      email,
      role,
      roleDisplayName,
    };

    dispatch({
      type: 'REFRESHUSERINFO',
      payload: {
        user,
      },
    });
  };

  const getCommonInfo = async (isAuthUrl) => {
    const userId = getInfoFromToken('userId');
    const needAccountInfo = userId !== null && !isAuthUrl && state.isInitialized;
    const resUserInfo = needAccountInfo ? await axios.get(PATH_SERVER_API.accounts.accountInfo(userId)) : null;
    let user = null;
    const accessToken = localStorage.getItem('accessToken');
    const otpVerifiedInfo = getOtpVerifiedInfo(accessToken);
    let otpEnabledInfo = false;
    let multiCompanyInfo = null;

    if (resUserInfo) {
      const { companyName, firstName, lastName, email, role, roleDisplayName, otpEnabled, multiCompany } =
        resUserInfo.data;
      user = {
        displayName: `${firstName} ${lastName}`,
        firstName,
        companyName,
        email,
        role,
        roleDisplayName,
      };

      otpEnabledInfo = otpEnabled;
      multiCompanyInfo = multiCompany;
    }

    const resSupportInfo = await axios.get(PATH_SERVER_API.support.supportEmail);
    const { supportEmail } = resSupportInfo.data;

    const resAnnouncementInfo = await axios.get(PATH_SERVER_API.support.announcement);
    const announcement = resAnnouncementInfo.data;

    if (needAccountInfo) {
      dispatch({
        type: 'SETALLCOMMONINFO',
        payload: {
          user,
          supportEmail,
          announcement,
          otpVerified: otpVerifiedInfo,
          otpEnabled: otpEnabledInfo,
          multiCompany: multiCompanyInfo,
        },
      });
    } else {
      dispatch({
        type: 'SETCOMMONINFO',
        payload: {
          supportEmail,
          announcement,
        },
      });
    }
  };

  const isValidAuthenticated = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken && state.isAuthenticated) {
      dispatch({ type: 'LOGOUT' });
      return false;
    }
    return state.isAuthenticated;
  };

  const getCompanyList = async () => {
    const response = await axios.get(PATH_SERVER_API.auth.multiCompany);
    const sortedData = response.data.sort((a, b) => a.companyName.localeCompare(b.companyName));
    return sortedData;
  };

  const setCompany = async (companyId) => {
    const response = await axios.put(`${PATH_SERVER_API.auth.multiCompany}/${companyId}`);

    const {
      accessToken,
      otpEnabled,
      otpRegistered,
      otpQRCodeImage,
      otpToken,
      email,
      role,
      companyName,
      roleDisplayName,
      firstName,
      lastName,
      multiCompany,
    } = response.data;

    setSession(accessToken);

    const user = {
      displayName: `${firstName} ${lastName}`,
      firstName,
      companyName,
      email,
      role,
      roleDisplayName,
    };

    const otpVerifiedInfo = getOtpVerifiedInfo(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        otpVerified: otpVerifiedInfo,
        otpEnabled,
        otpRegistered,
        otpQRCodeImage,
        otpToken,
        multiCompany,
      },
    });
  };

  const emptyCompany = async () => {
    const response = await axios.get(`${PATH_SERVER_API.auth.multiCompany}/empty`);

    const {
      accessToken,
      otpEnabled,
      otpRegistered,
      otpQRCodeImage,
      otpToken,
      email,
      role,
      companyName,
      roleDisplayName,
      firstName,
      lastName,
      multiCompany,
    } = response.data;

    setSession(accessToken);

    const user = {
      displayName: `${firstName} ${lastName}`,
      firstName,
      companyName,
      email,
      role,
      roleDisplayName,
    };

    const otpVerifiedInfo = getOtpVerifiedInfo(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        otpVerified: otpVerifiedInfo,
        otpEnabled,
        otpRegistered,
        otpQRCodeImage,
        otpToken,
        multiCompany,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        verify,
        refreshUserInfo,
        getCommonInfo,
        isValidAuthenticated,
        getCompanyList,
        setCompany,
        emptyCompany,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

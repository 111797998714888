import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { PATH_SERVER_API } from '../../../routes/paths';
import axios from '../../../utils/axios';
import { ADMIN_ERROR_MESSAGE } from '../../../const/common';

const initialState = {
  pathogenResponseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  confirmResponseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  selectedProjects: {
    companyId: 0,
    projectId: 0,
    data: [],
  },
  selectedSeasons: {
    companyId: 0,
    projectId: 0,
    data: [],
  },
  projectData: { companyId: 0, id: 0, name: '', serviceCode: 'MINI', active: true },
  seasonData: { companyId: 0, projectId: 0, id: 0, name: '', startDate: '', active: true },
  displayInfo: {
    farm: null,
    projectName: null,
    serviceTitle: null,
    serviceCode: null,
  },
  nameInfo: {
    farm: null,
    projectName: null,
    seasonName: null,
  },
  importDailyInfoFile: null,
  samplingDataFile: null,
  confirmedDailyInfoFileId: null,
  notificationStatus: { companyId: 0, show: null },
  enumServiceTypeDtos: [],
  pdfHtmlInfo: {
    projectNameInfoDto: { farm: null, projectName: null, seasonName: null, serviceCode: null, serviceTitle: null },
    samplingResultTableItems: [],
  },
};

const slice = createSlice({
  name: 'adminPathogen',
  initialState,
  reducers: {
    hasError(state, action) {
      state.isLoading = false;
      const { message, status } = action.payload;
      state.pathogenResponseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },
    hasFormError(state, action) {
      state.isLoading = false;
      const { message, status } = action.payload;
      state.confirmResponseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },
    clearResetForm(state) {
      state.projectData = initialState.projectData;
      state.seasonData = initialState.seasonData;
    },
    clearResponseStatus(state) {
      state.pathogenResponseStatus = initialState.pathogenResponseStatus;
      state.confirmResponseStatus = initialState.confirmResponseStatus;
    },
    clearImportDailyInfoFile(state) {
      state.importDailyInfoFile = initialState.importDailyInfoFile;
    },
    clearPdfHtmlInfo(state) {
      state.pdfHtmlInfo = initialState.pdfHtmlInfo;
    },
    setProjectsByCompanyId(state, action) {
      const { data, status, companyId } = action.payload;
      const { farm, projectManageDtos } = data;
      state.pathogenResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };
      state.selectedProjects = { companyId, data: projectManageDtos };
      state.displayInfo = { farm };
    },
    setProjectByProjectId(state, action) {
      const { status, companyId, data } = action.payload;
      const { farm } = data;
      state.pathogenResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };

      state.projectData = {
        companyId,
        id: data.projectDto.id,
        name: data.projectDto.name,
        serviceCode: data.projectDto.serviceCode,
        active: data.projectDto.active,
      };
      state.displayInfo = { farm };
    },
    setCompanyIdToProjectData(state, action) {
      state.projectData.companyId = action.payload.companyId;
    },
    setProjectsInfo(state, action) {
      const { status, data } = action.payload;
      const { enumServiceTypeDtos, farmName } = data;
      state.pathogenResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };
      state.enumServiceTypeDtos = enumServiceTypeDtos;
      state.displayInfo = {
        ...state.displayInfo,
        farm: farmName,
      };
    },
    setProjectAndSeasonsInfo(state, action) {
      const { status, data } = action.payload;
      const { farmName, projectName, serviceTypeName, serviceTypeCode } = data;
      state.pathogenResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };
      state.displayInfo = {
        farm: farmName,
        projectName,
        serviceTitle: serviceTypeName,
        serviceCode: serviceTypeCode,
      };
    },
    postAddProject(state, action) {
      const { status, data } = action.payload;
      state.confirmResponseStatus = {
        status,
        message: data.message,
        isSuccess: true,
        isSet: true,
      };
    },
    setSeasonsByProjectId(state, action) {
      const { data, status, companyId, projectId } = action.payload;
      const { farm, projectName, seasonDtos, serviceTitle, serviceCode } = data;
      state.pathogenResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };
      state.selectedSeasons = { companyId, projectId, data: seasonDtos };
      state.displayInfo = { farm, projectName, serviceTitle, serviceCode };
    },
    setSeasonsBySeasonsId(state, action) {
      const { status, companyId, projectId, data } = action.payload;
      const { farm, projectName, seasonDto, serviceTitle, serviceCode } = data;
      state.pathogenResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };

      state.seasonData = {
        companyId,
        projectId,
        id: seasonDto.id,
        name: seasonDto.name,
        startDate: seasonDto.startDate,
        active: data.active,
      };

      state.displayInfo = { farm, projectName, serviceTitle, serviceCode };
    },
    setIdToProjectData(state, action) {
      state.seasonData.companyId = action.payload.companyId;
      state.seasonData.projectId = action.payload.projectId;
    },
    postAddSeason(state, action) {
      const { data, status } = action.payload;
      state.confirmResponseStatus = {
        status,
        message: data.message,
        isSuccess: true,
        isSet: true,
      };
    },
    importDailyInfoFile(state, action) {
      const { data, status } = action.payload;
      state.pathogenResponseStatus = {
        status,
        message: action.payload.message,
        isSuccess: true,
        isSet: true,
      };
      state.importDailyInfoFile = data;
    },
    setConfirmedDailyInfoFileId(state, action) {
      const { status, message } = action.payload;
      state.confirmResponseStatus = {
        status,
        message,
        isSuccess: true,
        isSet: true,
      };
      state.importDailyInfoFile = initialState.importDailyInfoFile;
    },
    importSamplingDataFile(state, action) {
      const { status, message, data } = action.payload;
      state.confirmResponseStatus = {
        status,
        message,
        isSuccess: true,
        isSet: true,
      };
      state.importSamplingDataFile = data;
    },
    postSamplingResultSuccess(state, action) {
      const { data, status } = action.payload;
      state.confirmResponseStatus = {
        status,
        message: data.message,
        isSuccess: true,
        isSet: true,
      };
    },
    setNameInfo(state, action) {
      const { data, status } = action.payload;
      state.pathogenResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };
      state.nameInfo = data;
    },
    setDataImportNotificationStatus(state, action) {
      const { data } = action.payload;
      state.notificationStatus = data;
    },
    setDataImportNotification(state, action) {
      const { data } = action.payload;
      state.notificationStatus.show = !data;
    },
    setPdfHtmlInfo(state, action) {
      const { data, status } = action.payload;
      state.pathogenResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };
      state.pdfHtmlInfo = data;
    },
    postPdfHtmlSuccess(state, action) {
      const { status, data } = action.payload;
      state.confirmResponseStatus = {
        status,
        message: data.message,
        isSuccess: true,
        isSet: true,
      };
    },
  },
});

export default slice.reducer;

export function clearFormData() {
  return dispatch(slice.actions.clearResetForm);
}

export function clearResponseStatusData() {
  return dispatch(slice.actions.clearResponseStatus);
}

export function clearImportDailyInfoFileData() {
  return dispatch(slice.actions.clearImportDailyInfoFile);
}

export function clearPdfHtmlInfoTableData() {
  return dispatch(slice.actions.clearPdfHtmlInfo);
}

export function setCompanyIdToProjectData(companyId) {
  return dispatch(slice.actions.setCompanyIdToProjectData({ companyId }));
}

export function setIdToProjectData(companyId, projectId) {
  return dispatch(slice.actions.setIdToProjectData({ companyId, projectId }));
}

export function getProjectsInfo(companyId) {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.pathogens.projectsInfo, { params: { companyId } });
      const { data, status } = response;
      dispatch(slice.actions.setProjectsInfo({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getProjectAndSeasonInfo(companyId, projectId) {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.pathogens.seasonsInfo, {
        params: { companyId, projectId },
      });
      const { data, status } = response;
      dispatch(slice.actions.setProjectAndSeasonsInfo({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getProjectsByAccountId(companyId) {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.pathogens.projects(companyId));
      const { data, status } = response;
      dispatch(slice.actions.setProjectsByCompanyId({ data, status, companyId }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getProjectByProjectId(companyId, projectId) {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.pathogens.projectsByProjectId(companyId, projectId));
      const { data, status } = response;
      dispatch(slice.actions.setProjectByProjectId({ data, status, companyId }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function postProjects(projectData) {
  return async () => {
    try {
      const response = await axios.post(PATH_SERVER_API.admin.pathogens.addProjects, projectData);
      const { status, data } = response;
      dispatch(slice.actions.postAddProject({ status, data }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

export function getSeasonsByProjectId(companyId, projectId) {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.pathogens.seasons(companyId, projectId));
      const { data, status } = response;
      dispatch(slice.actions.setSeasonsByProjectId({ data, status, companyId, projectId }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getSeasonsBySeasonsId(companyId, projectId, seasonId) {
  return async () => {
    try {
      const response = await axios.get(
        PATH_SERVER_API.admin.pathogens.seasonsBySeasonId(companyId, projectId, seasonId)
      );
      const { data, status } = response;
      dispatch(slice.actions.setSeasonsBySeasonsId({ data, status, companyId, projectId }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function postSeasons(seasonData) {
  return async () => {
    try {
      const response = await axios.post(PATH_SERVER_API.admin.pathogens.addSeasons, seasonData);
      const { status, data } = response;
      dispatch(slice.actions.postAddSeason({ status, data }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

export function postImportDailyInfoFile(formData) {
  return async () => {
    try {
      const response = await axios.post(PATH_SERVER_API.admin.pathogens.postReportData, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      const { data, status } = response;
      dispatch(slice.actions.importDailyInfoFile({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function confirmDailyInfoFile(reqData) {
  return async () => {
    try {
      const response = await axios.post(`${PATH_SERVER_API.admin.pathogens.postReportData}/confirm`, reqData);
      const { status, message } = response.data;
      dispatch(slice.actions.setConfirmedDailyInfoFileId({ status, message }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

export function postSamplingDataFile(formData) {
  return async () => {
    try {
      const response = await axios.post(PATH_SERVER_API.admin.pathogens.postSamplingData, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      const { status, message } = response.data;
      dispatch(slice.actions.importSamplingDataFile({ status, message }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

export function postSamplingResult(formData, fileType) {
  return async () => {
    try {
      const response = await axios.post(PATH_SERVER_API.admin.pathogens.upload, formData, {
        params: { type: fileType },
        headers: { 'content-type': 'multipart/form-data' },
      });
      const { data, status } = response;
      dispatch(slice.actions.postSamplingResultSuccess({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

export function getNameInfo(companyId, projectId, seasonId) {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.pathogens.nameInfo(companyId, projectId, seasonId));
      const { data, status } = response;
      dispatch(slice.actions.setNameInfo({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getDataImportNotificationStatus(companyId) {
  return async () => {
    try {
      const config = companyId ? { params: { companyId } } : null;
      const response = await axios.get(PATH_SERVER_API.admin.pathogens.dataImportNotificationStatus, config);
      const { data } = response;
      dispatch(slice.actions.setDataImportNotificationStatus({ data }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getDataImportNotification(companyId) {
  return async () => {
    try {
      const config = companyId ? { params: { companyId } } : null;
      const response = await axios.get(PATH_SERVER_API.admin.pathogens.dataImportNotification, config);
      const { data } = response;
      dispatch(slice.actions.setDataImportNotification({ data }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getPdfHtmlInfo(companyId, projectId, seasonId) {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.pathogens.pdfHtmlInfo(companyId, projectId, seasonId));
      const { data, status } = response;
      dispatch(slice.actions.setPdfHtmlInfo({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

export function postPdfHtml(samplingResultId, isActive) {
  return async () => {
    try {
      const response = await axios.post(PATH_SERVER_API.admin.pathogens.updatePdfHtmlInfo, {
        samplingResultId,
        isActive,
      });
      const { data, status } = response;
      dispatch(slice.actions.postPdfHtmlSuccess({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import { PATH_USER } from '../../../routes/paths';
import MyAvatar from '../../../components/MyAvatar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { user } = useAuth();
  const whiteSpaceStyle = user?.displayName.length > 21 ? 'pre-line' : 'noWrap';

  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{ color: 'text.secondary', width: '240px', marginTop: '32px', marginBottom: '14px' }}
      >
        {user?.companyName}
      </Typography>
      <Link underline="none" color="inherit" component={RouterLink} to={PATH_USER.general.account}>
        <RootStyle
          sx={{
            ...(isCollapse && {
              bgcolor: 'transparent',
            }),
          }}
        >
          <MyAvatar />

          <Box
            sx={{
              ml: 2,
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.shorter,
                }),
              ...(isCollapse && {
                ml: 0,
                width: 0,
              }),
            }}
            data-cy="user-card"
          >
            <Typography variant="subtitle2" sx={{ whiteSpace: whiteSpaceStyle }}>
              {user?.displayName}
            </Typography>
            <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }} data-cy="user-role">
              {user?.roleDisplayName}
            </Typography>
          </Box>
        </RootStyle>
      </Link>
    </>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

const initialState = {
  savedSelected: { accountId: 0, projectId: 0, seasonId: 0 },
};

const slice = createSlice({
  name: 'saved',
  initialState,
  reducers: {
    clearSavedSelected(state) {
      state.savedSelected = initialState.savedSelected;
    },
    setSavedSelected: (state, action) => {
      state.savedSelected = action.payload;
    },
  },
});

export default slice.reducer;

export function saveSelected(accountId, projectId, seasonId) {
  return dispatch(slice.actions.setSavedSelected({ accountId, projectId, seasonId }));
}

export function clearSavedSelectedData() {
  return dispatch(slice.actions.clearSavedSelected);
}

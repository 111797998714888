import { PATH_ADMIN, PATH_DASHBOARD, PATH_PATHOGENS, PATH_SAMPLING, PATH_SUBMIT_SAMPLE } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  invoice: getIcon('ic_invoice'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  user: getIcon('ic_user'),
  upload: getIcon('ic_upload'),
  chat: getIcon('ic_chat'),
  link: getIcon('ic_link'),
  kanban: getIcon('ic_kanban'),
  announcement: getIcon('ic_announcement'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.general.overview, icon: ICONS.dashboard, roles: ['USER'] },
      { title: 'pathogens', path: PATH_PATHOGENS.root, icon: ICONS.analytics, roles: ['USER'] },
      { title: 'sampling', path: PATH_SAMPLING.root, icon: ICONS.invoice, roles: ['USER'] },
      { title: 'submit_sample', path: PATH_SUBMIT_SAMPLE.root, icon: ICONS.upload, roles: ['USER'] },
      { title: 'contact_support', icon: ICONS.chat, roles: ['USER'] },
      { title: 'company_management', path: PATH_ADMIN.companyManagement.root, icon: ICONS.user, roles: ['ADMIN'] },
      { title: 'user_management', path: PATH_ADMIN.userManagement.root, icon: ICONS.user, roles: ['ADMIN'] },
      {
        title: 'pathogen_management',
        path: PATH_ADMIN.pathogenManagement.root,
        icon: ICONS.link,
        roles: ['ADMIN'],
      },
      {
        title: 'announcement',
        path: PATH_ADMIN.announcementManagement.root,
        icon: ICONS.announcement,
        roles: ['ADMIN'],
      },
      {
        title: 'slideshow_management',
        path: PATH_ADMIN.slideshowManagement.root,
        icon: ICONS.kanban,
        roles: ['ADMIN'],
      },
      {
        title: 'original_file_upload',
        path: PATH_ADMIN.originalFileUpload.upload,
        icon: ICONS.upload,
        roles: ['UPLOADER'],
      },
    ],
  },
];

export default navConfig;

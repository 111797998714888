import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { PATH_SERVER_API } from '../../../routes/paths';
import axios from '../../../utils/axios';
import { ADMIN_ERROR_MESSAGE } from '../../../const/common';

const initialState = {
  companyResponseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  formResponseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  company: {
    type: '',
    data: [],
  },
  companyInfoData: {
    id: 0,
    companyName: '',
    phone: '',
    country: '',
    street: '',
    state: '',
    city: '',
    zipcode: '',
    about: '',
    active: false,
  },
};

const slice = createSlice({
  name: 'companyAccount',
  initialState,
  reducers: {
    hasError(state, action) {
      state.isLoading = false;
      const { message, status } = action.payload;
      state.companyResponseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },

    hasFormError(state, action) {
      const { message, status } = action.payload;
      state.formResponseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },

    clearResponseStatus(state) {
      state.companyResponseStatus = initialState.companyResponseStatus;
      state.formResponseStatus = initialState.formResponseStatus;
    },

    setCompany(state, action) {
      const { status, data, type } = action.payload;
      state.companyResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };
      state.company = {
        type,
        data,
      };
    },
    setCompanyInfoData(state, action) {
      // To handle Null values
      Object.entries(action.payload).forEach((el) => {
        action.payload = {
          ...action.payload,
          [el[0]]: el[1] !== null ? el[1] : '',
        };
      });
      state.companyInfoData = action.payload;
    },

    setCreateAndUpdateCompany(state, action) {
      const { status, data } = action.payload;
      state.formResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
        message: data.message,
      };
    },
    setAddUserToCompany(state, action) {
      const { status, data } = action.payload;
      state.formResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
        message: data.message,
      };
    },
  },
});

export default slice.reducer;

export function clearResponseStatusData() {
  return dispatch(slice.actions.clearResponseStatus);
}

export function getAllCompany(target) {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.companies.getAllCompanies(target));
      const { data, status } = response;
      dispatch(slice.actions.setCompany({ data, status, type: target }));
    } catch (error) {
      const { status } = error.response;
      dispatch(slice.actions.hasError({ message: ADMIN_ERROR_MESSAGE[status] ?? error.message, status }));
    }
  };
}

export function getCompanyProfile(companyId) {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.companies.getCompanyProfile(companyId));
      dispatch(slice.actions.setCompanyInfoData(response.data));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function createAndUpdateCompany(formData) {
  return async () => {
    try {
      const response = await axios.put(PATH_SERVER_API.admin.companies.createAndUpdateCompanyProfile, formData);
      const { data, status } = response;
      dispatch(slice.actions.setCreateAndUpdateCompany({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

export function addUserToCompany(selectedData) {
  return async () => {
    try {
      const response = await axios.put(PATH_SERVER_API.admin.companies.addUserToCompany, selectedData);
      const { data, status } = response;
      dispatch(slice.actions.setAddUserToCompany({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}
export function deleteUserToCompany(deleteData) {
  return async () => {
    try {
      await axios.delete(PATH_SERVER_API.admin.companies.deleteUserToCompany, { data: deleteData });
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

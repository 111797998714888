import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { PATH_SERVER_API } from '../../routes/paths';
import axios from '../../utils/axios';
import { getUserIdFromToken } from '../../utils/jwt';
import { ERROR_MESSAGE } from '../../const/common';

const initialState = {
  userInfoData: {
    id: 0,
    firstName: '',
    lastName: '',
    companyName: '',
  },
  responseStatus: { message: '', status: null, isSuccess: false, isSet: false },
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    hasError(state, action) {
      state.isLoading = false;
      const { message, status } = action.payload;
      state.responseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },
    clearResponseStatus(state) {
      state.responseStatus = initialState.responseStatus;
    },

    setAccountInfo: (state, action) => {
      // To handle Null values
      Object.entries(action.payload).forEach((el) => {
        action.payload = {
          ...action.payload,
          [el[0]]: el[1] ? el[1] : '',
        };
      });
      state.userInfoData = action.payload;
    },

    setFormResponse: (state, action) => {
      const { status, data } = action.payload;
      state.responseStatus = {
        status,
        message: data.message,
        isSet: true,
        isSuccess: true,
      };
    },

    setResponseStatus: (state, action) => {
      const { message, status } = action.payload;
      state.responseStatus = {
        message,
        status,
        isSet: true,
        isSuccess: true,
      };
    },
  },
});

// Reducer
export default slice.reducer;

// Change state
export function clearResponseStatusData() {
  return dispatch(slice.actions.clearResponseStatus);
}

// Api Call Functions
export function getAccountInfo() {
  return async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const userId = getUserIdFromToken(accessToken);
      const response = await axios.get(PATH_SERVER_API.accounts.accountProfile(userId));
      dispatch(slice.actions.setAccountInfo(response.data));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function updateAccountInfo(userInfoData) {
  return async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const userId = getUserIdFromToken(accessToken);
      userInfoData.firstName = userInfoData.firstName.trim();
      const response = await axios.put(PATH_SERVER_API.accounts.accountProfile(userId), userInfoData);
      const { status, data } = response;
      dispatch(slice.actions.setFormResponse({ status, data }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function changePassword(changePasswordData) {
  return async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const userId = getUserIdFromToken(accessToken);
      const response = await axios.put(PATH_SERVER_API.accounts.changePassword(userId), changePasswordData);
      const { status, message } = response.data;
      dispatch(slice.actions.setResponseStatus({ status, message }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function verifyToken(token) {
  return async () => {
    try {
      const response = await axios.post(PATH_SERVER_API.accounts.verifyToken, { token });
      const { status, message } = response.data;
      dispatch(slice.actions.setResponseStatus({ status, message }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

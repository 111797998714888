import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { PATH_SERVER_API } from '../../../routes/paths';
import axios from '../../../utils/axios';
import { ADMIN_ERROR_MESSAGE } from '../../../const/common';

const initialState = {
  accountResponseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  formResponseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  invitationResponseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  accounts: {
    type: '',
    accountManageDtos: [],
    companyName: '',
  },
  userInfoData: {
    id: 0,
    email: '',
    firstName: '',
    lastName: '',
    active: false,
  },
  userManagementListData: {
    data: [],
  },
};

const slice = createSlice({
  name: 'adminAccount',
  initialState,
  reducers: {
    hasError(state, action) {
      state.isLoading = false;
      const { message, status } = action.payload;
      state.accountResponseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },
    hasFormError(state, action) {
      const { message, status } = action.payload;
      state.formResponseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },
    clearResponseStatus(state) {
      state.accountResponseStatus = initialState.accountResponseStatus;
      state.formResponseStatus = initialState.formResponseStatus;
      state.invitationResponseStatus = initialState.invitationResponseStatus;
    },
    setAccounts(state, action) {
      const { status, data, type } = action.payload;
      const { accountManageDtos, companyName } = data;

      state.accountResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };
      state.accounts = {
        type,
        accountManageDtos,
        companyName,
      };
    },
    inviteUserSuccess(state, action) {
      const { data, status } = action.payload;
      state.invitationResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
        message: data.message,
      };
    },
    setUserManagementData(state, action) {
      const { data, status } = action.payload;
      state.accountResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };
      state.userManagementListData = { data };
    },
    setUserInfoData(state, action) {
      // To handle Null values
      Object.entries(action.payload).forEach((el) => {
        action.payload = {
          ...action.payload,
          [el[0]]: el[1] !== null ? el[1] : '',
        };
      });
      state.userInfoData = action.payload;
    },
    setNewUser(state, action) {
      const { status, data } = action.payload;
      state.formResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
        message: data.message,
      };
    },
    setEditUser(state, action) {
      const { status, data } = action.payload;
      state.formResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
        message: data.message,
      };
    },
  },
});

export default slice.reducer;

export function clearResponseStatusData() {
  return dispatch(slice.actions.clearResponseStatus);
}

// target is one of [all, user, admin]
export function getAllAccounts(companyId, target) {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.accounts.getAllAccounts(companyId, target));
      const { data, status } = response;
      dispatch(slice.actions.setAccounts({ data, status, type: target }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function inviteUser(id) {
  return async () => {
    try {
      const response = await axios.post(PATH_SERVER_API.admin.accounts.invitation, { id });
      const { data, status } = response;
      dispatch(slice.actions.inviteUserSuccess({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

// User Management without company
export function getAllUsers() {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.accounts.getAllUsers);
      const { data, status } = response;
      dispatch(slice.actions.setUserManagementData({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getProfile(accountId) {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.accounts.getProfile(accountId));
      dispatch(slice.actions.setUserInfoData(response.data));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function newUser(formData) {
  return async () => {
    try {
      const response = await axios.put(PATH_SERVER_API.admin.accounts.newUser, formData);
      const { data, status } = response;
      dispatch(slice.actions.setNewUser({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

export function editUser(formData) {
  return async () => {
    try {
      const response = await axios.put(PATH_SERVER_API.admin.accounts.editUser, formData);
      const { data, status } = response;
      dispatch(slice.actions.setEditUser({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

const en = {
  account: `Account`,
  account_settings: `Account Settings`,
  active: `Active`,
  add: `Add`,
  add_user: `Add User`,
  admin: `Admin`,
  all: `All`,
  announcement: `Announcements`,
  announcement_management: `Announcement Management`,
  back: `Back`,
  bacteria_archaea_human: `Bacteria/Archaea (human)`,
  bacteria_archaea_plant: `Bacteria/Archaea (plant)`,
  cancel: `Cancel`,
  cannot_be_blank: `This field cannot be left blank.`,
  change_password: `Change Password`,
  chart_mini_title: `Pathogen Mini`,
  chart_mini_guide: `Graph is not available for Pathogen Mini projects. Please click More Details to see the data table.`,
  chart_title: `Top 10 Species`,
  company: 'Company',
  company_management: `Company Management`,
  company_name: 'Company Name',
  confirm: `Confirm`,
  confirm_new_password: `Confirm New Password`,
  contact_support: `Contact Support`,
  current_password: `Current Password`,
  dashboard: `Dashboard`,
  data_table: `Data Table`,
  day: 'Day',
  download: `Download`,
  delete: `Delete`,
  detail: `Detail`,
  detail_report: `Detail Report`,
  drop_files: `Drop files here or click`,
  drop_files_2: `browse`,
  drop_files_3: `thorough your machine`,
  edit_announcement: `Edit Announcement`,
  edit_company: `Edit Company`,
  edit_pathogen_url: `Edit Pathogen URL`,
  edit_project: `Edit Project`,
  edit_season: `Edit Season`,
  edit_user: `Edit User`,
  email: `Email`,
  email_address: `Email address`,
  failed_download: `Failed to download the file.`,
  first_name: `First Name`,
  forgot_password: `Forgot password?`,
  fungi: `Fungi`,
  general_eukaryotes: `General Eukaryotes`,
  home: `Home`,
  inactive: `Inactive`,
  invalid_email: `Invalid email address`,
  invalid_email2: `Email must be a valid email address`,
  invalid_url: `Invalid URL`,
  last_name: `Last Name`,
  location: `Location`,
  login: `Login`,
  logout: `Logout`,
  more_details: `More Details`,
  name: `Name`,
  new_announcement: `New Announcement`,
  new_company: `New Company`,
  new_password: `New Password`,
  new_project: `New Project`,
  new_season: `New Season`,
  new_user: `New user`,
  no_data: `No Data`,
  no_resources: `No Resources`,
  original_file_upload: `Original File Upload`,
  password: `Password`,
  password_match: `Passwords must match`,
  password_rule: `Password must include 8 characters, at least 1 lowercase letter, 1 uppercase letter, a number and a symbol.`,
  pathogen: `Pathogen`,
  pathogen_group: `Pathogen Group`,
  pathogen_group_s: `Pathogen Group(s)`,
  pathogen_groups: `Pathogen Groups`,
  pathogen_management: `Pathogen Management`,
  pathogen_s: `Pathogen(s)`,
  pathogen_subtitle: `The chart below only includes the most relevant species for the grower, and the values are the percentage of the total species found.`,
  pathogen_url: `Pathogen URL`,
  pathogens: `Pathogens`,
  project: `Project`,
  projects: `Projects`,
  remove: `Remove`,
  remove_all: `Remove All`,
  remove_profile_confirm_message: `Are you sure you want to remove this profile?`,
  require_confirm_password: `Confirm password is required`,
  require_email: `Email is required`,
  require_firstname: `First Name is required`,
  require_lastname: `Last Name is required`,
  require_current_password: `Current Password is required`,
  require_new_password: `New Password is required`,
  require_password: `Password is required`,
  reset_password: `Reset password`,
  resources: `Resources`,
  sample: `Sample`,
  sampling: `Sampling`,
  save: `Save`,
  save_changes: `Save Changes`,
  search: `Search...`,
  season: `Season`,
  seasons: `Seasons`,
  select_files: `Select files`,
  select_project_and_season_title: `Project/Season`,
  select_project_and_season_subtitle: `Select project and season to view details`,
  set_up_password: `Set Up Password`,
  settings: `Settings`,
  status: `Status`,
  submit: `Submit`,
  submit_policy: `By submitting, I agree to the`,
  submit_policy_2: `Terms and Conditions`,
  submit_policy_3: `and the`,
  submit_policy_4: `Privacy Policy.`,
  submit_sample: `Submit Sample`,
  switch_operation: `Switch Operation`,
  select_operation: `Select Operation`,
  slideshow_management: `Slide Show Management`,
  slideshow_resources: `Slide Show Resources`,
  there_are_no_data: `There are no data`,
  unable: `Unable to logout!`,
  upload: `Upload the Sample Submission Form`,
  upload_files: `Upload Files`,
  url_status: `URL Status`,
  user: `User`,
  user_management: `User Management`,
  users: `Users`,
  view: `View`,
  view_detail: `View Detail`,
  view_report: `View Report`,
  welcome: `Welcome`,
  text_contact_support: `Having problems accessing the portal? Please contact`,
  text_contact_support_part2: `support team`,
  text_contact_support_part3: `for further assistance.`,
  type: `Type`,
  type_alert: `Alert`,
  type_info: `Info`,
  powered_by: `Powered by UBIX Innovations Inc.`,
  linked: `Linked`,
  unlinked: `Unlinked`,

  // message
  message: {
    save: `Successfully Saved`,
    sampling_submit: `Successfully Sampling Request Submitted`,
  },

  // sort by page
  page: {
    login: {
      title: `Welcome to Growers' Portal`,
      reset_message: `We've sent the reset password link to`,
      reset_message_part2: `If you can't find the email, please check your spam folder or make sure your email address is registered.`,
    },
    forgot_password: {
      title: `Forgot your password?`,
      description: `Enter the email address you registered with and we will send the password reset link via email.`,
    },
    new_password: {
      title: `Delighted to have you on board!`,
      reset_title: `Reset your password`,
      desc: `Please set up your password to complete the registration.`,
      invalid_message: `Invalid reset password link. Please try resetting your password again.`,
      set_password: `Set password`,
    },
    verify_code: {
      verify_code: `Verify Code`,
      title: `Two-Factor Authentication`,
      unregistered_info: `To confirm the application is set up correctly, enter the security code that appears on your phone.`,
      registered_info: `Enter the token code from Google Authenticator or Microsoft Authenticator.`,
      google_auth: `Google Authenticator`,
      microsoft_auth: `Microsoft Authenticator`,
      or: `or`,
      on_your_phone: `on your phone`,
      qr_info: `Open the app and tap begin setup`,
      qr_info_part2: `Scan the QR code`,
      require_code: `Code is required`,
      invalid_code: `Invalid code`,
    },

    user: {
      select_operation: {
        title: `Select your operation`,
        table_head: `My operations`,
        no_company: `There is no operation assigned to you`,
      },
      dashboard: {
        page_title: `Main Dashboard`,
        title: `Welcome back`,
        subtitle: `Are you ready to submit your next sample`,
      },
      no_project_dashboard: {
        title: `No projects yet`,
        subtitle: `Project info will appear once samples are submitted and analyzed.`,
        description: `Welcome! We are delighted to have you on board.`,
        submit_sample_title: `Submit your first sample!`,
        submit_sample_desc: `We can help identify plant pathogens and human pathogens in your hydroponic system and crops’ root surfaces.`,
      },
      detail_report: {
        reports: `Reports`,
        type: `Type`,
        created_date: `Created Date`,
        link: `Link`,
        there_are_no_reports: `There are no reports`,
      },
      sampling: {
        add_sampling_data: `Add Sampling data`,
        location_name: `Location Name`,
        sampling_type: `Sampling Type`,
        sampling_from: `Sampling From`,
        temp_sample: `Temp Sample`,
        last_updated: `Last Updated`,
        additional_info: `Additional Info`,
        temp_atmosphere: `Temp Atmosphere`,
        save_sampling_form: `Save Sampling Form`,
      },
      submit_sample: {
        title: `Submit your samples`,
        description: `Download the form and fill in the info. Once completed, please upload your completed Sample Submission Form. For multiple samples, please use a single form. Print the Sample Submission Form to send with the samples.`,
        note_span: `Note:`,
        note: `that the information we collect on farms is kept confidential. Any metadata collected and sequencing data generated are used to improve our in-house pathogen database and to help better serve growers.`,
        download_button: `Download Sample Submission Form`,
        learn_more_button: `Learn more about Sample Submission`,
        courier: `Courier*`,
        trackingID: `Tracking ID/URL*`,
        textarea: `When do you plan to send your samples?`,
        alert: `Sample has been successfully submitted!`,
        require_courier: `Courier is required`,
        require_tracking: `Tracking Info is required`,
      },
    },

    admin: {
      company_management: {
        manage_users: `Manage Users`,
        manage_projects: `Manage Projects`,
        phone_number: `Phone Number`,
        country: `Country`,
        street: `Street`,
        state_region: `State/Region`,
        city: `City`,
        zip_code: `Zip Code`,
        about: `About`,
      },
      manage_users: {
        edit_profile: `Edit Profile`,
        remove_profile: `Remove Profile`,
        email_address: `Email Address`,
        confirm_password: `Confirm Password`,
        send_invitation: `Send Invitation`,
      },
      manage_projects: {
        project_name: `Project name`,
        service_type: `Service Type`,
        created_at: `Created At`,
        manage_seasons: `Manage Seasons`,
        require_project_name: `Project Name is required`,
      },
      manage_seasons: {
        season_name: `Season Name`,
        start_date: `Start Date`,
        require_date: `Start Date is required`,
        require_season_name: `Season Name is required`,
      },
      import_data: {
        import_report_data: `Import Report Data`,
        import_pathogen_data: `Import Pathogen Data`,
        import_pdf_data: `Import PDF Data`,
        import_html_data: `Import HTML Data`,
        guide_message_to_admin: `Guide Message to Admin`,
        guide_description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ornare non nunc vel finibus. Curabitur eget libero orci. Mauris bibendum, arcu ac lobortis congue, ligula ante lacinia purus, faucibus finibus nisl diam non tellus`,
        invalid_type: `Invalid Data Type`,
        require_file: `File is required`,
        sample_id: `Sample ID`,
        date_time: `Date Time`,
        sample_type: `Sample Type`,
        sample_from: `Sample From`,
        light: `Light`,
      },
      pdf_html: {
        manage_pdf_html: `Manage PDF/HTML`,
        type: `Type`,
        created_date: `Created Date`,
        inactive_file: `Inactive File`,
        active_file: `Active File`,
      },
      pathogens_detail: {
        user_pathogen_detail: `User Pathogens Detail`,
        pathogens_detail: `Pathogens Detail`,
        pathogen_mini: `Pathogen Mini`,
        send_notifications: `Send notifications`,
        sampling_detail: `Sampling Detail`,
      },
      pathogen_management: {
        pathogen_name: `Pathogen Name`,
        edit_url: `Edit URL`,
        url: `URL`,
      },
      announcement_management: {
        title: `Title`,
        description: `Description`,
        start_date: `Start Date`,
        end_date: `End Date`,
        require_start_date: `Start Date is required`,
        require_end_date: `End Date is required`,
        require_title: 'Title is required',
        require_desc: 'Description is required',
      },
      slideshow_management: {
        title_desc: `Title and Short Description`,
        title: `Title`,
        group: `Group`,
        short_description: `Short Description`,
        link_url: `Link URL`,
        image: `Image`,
        slideshow_image: `Slide Show Image`,
        slide_order: `Slide Order`,
        edit_slideshow: `Edit Slide Show`,
        new_slideshow: `New Slide Show`,
        order_number_type_error: `Order number must be a number`,
        click_to_view_url: `Click to view URL`,
      },
    },

    error: {
      forbidden: `403 Forbidden`,
      no_permission: `No permission`,
      forbidden_info: `The page you're trying access has restricted access.`,
      forbidden_info_part2: `Please refer to your system administrator`,
      go_to_home: `Go to Home`,
      page_not_found: `Page Not Found`,
      notfound_info: `Sorry, we couldn’t find the page you’re looking for.`,
      notfound_info_part2: `You may have typed the address incorrectly, or the link you clicked may be broken.`,
      back_to_home: `Back to Home`,
      internal_server_error: `500 Internal Server Error`,
      server_error_info: `There was an error, please try again later.`,
      permission_denied: `Permission Denied`,
    },
  },
};

export default en;

import { createSlice } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { PATH_SERVER_API } from '../../../routes/paths';
import { ADMIN_ERROR_MESSAGE } from '../../../const/common';
import { dispatch } from '../../store';

const initialState = {
  responseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  confirmResponseStatus: { message: '', status: null, isSuccess: false, isSet: false },
};

const slice = createSlice({
  name: 'adminUploader',
  initialState,
  reducers: {
    hasError(state, action) {
      state.isLoading = false;
      const { message, status } = action.payload;
      state.responseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },
    hasFormError(state, action) {
      state.isLoading = false;
      const { message, status } = action.payload;
      state.confirmResponseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },
    postOriginalFilesSuccess(state, action) {
      const { data, status } = action.payload;
      state.confirmResponseStatus = {
        status,
        message: data.message,
        isSuccess: true,
        isSet: true,
      };
    },
    clearResponseStatus(state) {
      state.responseStatus = initialState.responseStatus;
      state.confirmResponseStatus = initialState.confirmResponseStatus;
    },
  },
});

export default slice.reducer;

export function clearResponseStatusData() {
  return dispatch(slice.actions.clearResponseStatus);
}

export function postOriginalFiles(formData) {
  return async () => {
    try {
      const response = await axios.post(PATH_SERVER_API.uploader.originalFile, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      const { data, status } = response;
      dispatch(slice.actions.postOriginalFilesSuccess({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

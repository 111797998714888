import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { getPathogenAPIPathByRole, PATH_ADMIN, PATH_SAMPLING, PATH_SERVER_API } from '../../routes/paths';
import axios from '../../utils/axios';
import {
  findDataById,
  findDataByKey,
  findFilteredListByKey,
  findFilteredListByKeyOrderByAlphabet,
} from '../../utils/getData';
import { getUserIdFromToken } from '../../utils/jwt';
import { ERROR_MESSAGE } from '../../const/common';
import { linkUrl, chartLabel } from '../../utils/getChartXLabel';

let isAdmin = false;

const initialState = {
  isLoading: false,
  responseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  formResponseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  selected: {
    projects: { id: 0, name: '', projectOrder: 0, seasons: [], serviceType: { code: null, title: null } },
    seasons: { id: 0, name: '', projectOrder: 0 },
    locations: { id: 0, name: '' },
    dailyInfo: {
      dailyInfoId: 0,
      displayDay: 0,
    },
  },
  projects: [],
  samplingLocations: [],
  seasons: [],
  seasonChart: {},
  seasonTableHead: [],
  seasonSameDayTableHead: [],
  chartXLabelObj: {},
  seasonChartWidget: { pathogenDtos: [] },
  dailyChart: {},
  seasonTableObj: { seasonTableItems: [], isLoading: false },
  pathogensNameList: [],
  pathogenGroupList: [],
  dailyTable: [],
  dailyInfoTableItemObj: { dailyInfoTableItems: [], isLoading: false },
  dailyInfoFilterMenuList: {
    dayList: [],
    locationList: [],
  },
  samplingList: [],
  dailyInfoData: {
    isLoaded: false,
    formData: {
      id: 0,
      dailyInfoId: '',
      ph: '',
      tempSample: '',
      tempAtmosphere: '',
      ec: '',
      rh: '',
      light: '',
      co2: '',
    },
    newDataId: null,
  },
  samplingResultTableItemObj: { samplingResultTableItems: [], isLoading: false },
  widgets: {
    1: null,
    2: null,
    3: null,
  },
};

const slice = createSlice({
  name: 'pathogen',
  initialState,
  reducers: {
    initialize(state) {
      Object.assign(state, initialState); // state = initialState is not working
      isAdmin = false;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      const { message, status } = action.payload;
      state.responseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },
    hasFormError(state, action) {
      state.isLoading = false;
      const { message, status } = action.payload;
      state.formResponseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },
    resetSelect(state) {
      state.selected = initialState.selected;
    },
    setSelect(state, action) {
      Object.entries(action.payload).forEach((el) => {
        state.selected = { ...state.selected, [el[0]]: el[1] };
      });
    },
    clearResponseStatus(state) {
      state.responseStatus = initialState.responseStatus;
      state.formResponseStatus = initialState.formResponseStatus;
    },
    clearAllChartAndTable(state) {
      state.seasonTableObj = initialState.seasonTableObj;
      state.seasonTableHead = initialState.seasonTableHead;
      state.seasonSameDayTableHead = initialState.seasonSameDayTableHead;
      state.dailyTable = initialState.dailyTable;
      state.dailyInfoTableItemObj = initialState.dailyInfoTableItemObj;
      state.seasonChart = initialState.seasonChart;
      state.dailyChart = initialState.dailyChart;
    },
    clearDataTable(state) {
      state.seasonTableObj = initialState.seasonTableObj;
    },
    clearDailyChartData(state) {
      state.dailyChart = initialState.dailyChart;
    },
    setProject(state, action) {
      state.isLoading = false;
      const { projects, samplingLocations } = action.payload.data;
      state.responseStatus = {
        status: action.payload.status,
        isSuccess: true,
        isSet: true,
      };

      projects.forEach((project) => {
        if (!project.seasons.length) project.isDisabled = true;
        else {
          project.seasons.forEach((season) => {
            project.isDisabled = !season.samplingLocations.length;
          });
        }
      });

      state.projects = projects;
      state.samplingLocations = samplingLocations;
    },
    setSeasonChart(state, action) {
      state.isLoading = false;
      state.responseStatus = {
        status: action.payload.status,
        isSuccess: true,
        isSet: true,
      };

      const { isMini } = action.payload;
      const { companyId } = action.payload.params;
      const { categories, projectId, seasonId, dailyInfoIds } = action.payload.data;

      const chartXLabelObj = {
        label: categories,
        projectId,
        seasonId,
        dailyInfoIds,
        isMini,
        companyId,
        chartLabel: (text) => chartLabel(chartXLabelObj.label, dailyInfoIds, text),
        linkUrl: (text) =>
          linkUrl(isMini, isAdmin, companyId, projectId, seasonId, dailyInfoIds, chartXLabelObj.label, text),
      };

      state.seasonChart = action.payload.data;
      state.chartXLabelObj = chartXLabelObj;
    },

    setSeasonChartWidget(state, action) {
      state.responseStatus = {
        status: action.payload.status,
        isSuccess: true,
        isSet: true,
      };
      state.seasonChartWidget = action.payload.data;
    },
    setDailyChart(state, action) {
      const { data, status, isMini } = action.payload;

      state.responseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };
      const responseData = data;
      const fieldString = isMini ? 'pathogenDto' : 'pathogenDtos';

      responseData[fieldString].forEach((pathogen) => {
        pathogen.data = [pathogen.rowValues];
      });

      state.dailyChart = responseData;
    },
    setSeasonTable(state, action) {
      state.responseStatus = {
        status: action.payload.status,
        isSuccess: true,
        isSet: true,
      };
      const { seasonTableItems, projectId, seasonId, dailyInfoIds } = action.payload.data;
      const pathogensNameList = findFilteredListByKey(seasonTableItems, 'pathogenName');
      const pathogenGroupList = findFilteredListByKeyOrderByAlphabet(seasonTableItems, 'pathogenGroup');
      state.seasonTableObj.isLoading = true;
      state.seasonTableObj.seasonTableItems = seasonTableItems;
      state.pathogensNameList = pathogensNameList;
      state.pathogenGroupList = pathogenGroupList;

      const baseTableHead = action.payload.isMini
        ? [
            { id: 'group', label: 'pathogen_group' },
            { id: 'name', label: 'pathogen' },
            { id: 'sample', label: 'sample' },
          ]
        : [
            { id: 'group', label: 'pathogen_group' },
            { id: 'name', label: 'pathogen' },
          ];

      const { isMini } = action.payload;
      const { companyId } = action.payload.params;

      const link = (index) => {
        const isLinkVal = isMini === undefined && projectId !== null && seasonId !== null && dailyInfoIds !== null;
        if (isAdmin && isLinkVal && companyId !== null) {
          return PATH_ADMIN.companyManagement.samplingDetail(companyId, projectId, seasonId, dailyInfoIds[index]);
        }
        if (!isAdmin && isLinkVal) {
          return PATH_SAMPLING.general.edit(projectId, seasonId, dailyInfoIds[index]);
        }
        return null;
      };

      const seasonTableHead = action.payload.data.categories.map((category, index) => ({
        id: category,
        label: 'day',
        linkUrl: link(index),
        addDay: true,
      }));

      const seasonSameDayTableHead = action.payload.data.categories.map((category, index) => ({
        id: category,
        label: category,
        linkUrl: link(index),
      }));

      state.seasonTableHead = baseTableHead.concat(seasonTableHead);
      state.seasonSameDayTableHead = baseTableHead.concat(seasonSameDayTableHead);
    },
    setDailyTable(state, action) {
      state.responseStatus = {
        status: action.payload.status,
        isSuccess: true,
        isSet: true,
      };
      const { dailyTableItems } = action.payload.data;
      const pathogensNameList = findFilteredListByKey(dailyTableItems, 'pathogenName');
      const pathogenGroupList = findFilteredListByKeyOrderByAlphabet(dailyTableItems, 'pathogenGroup');
      state.dailyTable = dailyTableItems;
      state.pathogensNameList = pathogensNameList;
      state.pathogenGroupList = pathogenGroupList;
    },
    setDailyInfoTable(state, action) {
      state.responseStatus = {
        status: action.payload.status,
        isSuccess: true,
        isSet: true,
      };
      const { dailyInfoTableItems } = action.payload.data;
      const dayList = ['All'].concat(findFilteredListByKey(dailyInfoTableItems, 'day'));
      const locationList = ['All'].concat(findFilteredListByKey(dailyInfoTableItems, 'enumSamplingLocationName'));
      state.dailyInfoTableItemObj.dailyInfoTableItems = dailyInfoTableItems;
      state.dailyInfoTableItemObj.isLoading = true;
      state.dailyInfoFilterMenuList = { dayList, locationList };
    },
    setSamplingList(state, action) {
      state.responseStatus = {
        status: action.payload.status,
        isSuccess: true,
        isSet: true,
      };
      state.samplingList = action.payload.data;
    },
    setDailyInfo(state, action) {
      state.responseStatus = {
        status: action.payload.status,
        isSuccess: true,
        isSet: true,
      };
      Object.entries(action.payload.data).forEach((el) => {
        action.payload.data = {
          ...action.payload.data,
          [el[0]]: el[1] ? el[1] : '',
        };
      });
      state.dailyInfoData.isLoaded = true;
      state.dailyInfoData.formData = action.payload.data;
    },
    postDailyInfoSuccess(state, action) {
      // message part is temp, it will be changed as response message from server
      state.formResponseStatus = {
        status: action.payload.status,
        message: 'message.save',
        isSuccess: true,
        isSet: true,
      };
      state.dailyInfoData.newDataId = action.payload.data;
    },
    setSamplingResultTable(state, action) {
      state.responseStatus = {
        status: action.payload.status,
        isSuccess: true,
        isSet: true,
      };
      state.samplingResultTableItemObj.samplingResultTableItems = action.payload.data.samplingResultTableItems;
      state.samplingResultTableItemObj.isLoading = true;
    },
    postSamplingRequestSuccess(state, action) {
      state.formResponseStatus = {
        status: action.payload.status,
        message: 'message.sampling_submit',
        isSuccess: true,
        isSet: true,
      };
    },
    setWidgets(state, action) {
      const { data, status } = action.payload;
      state.responseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };

      if (!data || data.length <= 0) {
        state.widgets = initialState.widgets;
      } else {
        state.widgets['1'] = findDataByKey(data, 'number', 1);
        state.widgets['2'] = findDataByKey(data, 'number', 2);
        state.widgets['3'] = findDataByKey(data, 'number', 3);
      }
    },
    setWidget(state, action) {
      const { data, status } = action.payload;
      state.responseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };

      state.widgets[data.number] = data;
    },
  },
});

// Reducer
export default slice.reducer;

// Change state
export function initializePathogen() {
  return dispatch(slice.actions.initialize);
}

export function resetSelectedData() {
  return dispatch(slice.actions.resetSelect);
}

export function setSelectData(data) {
  return dispatch(slice.actions.setSelect(data));
}

export function clearResponseStatusData() {
  return dispatch(slice.actions.clearResponseStatus);
}

export function clearAllChartAndTableData() {
  return dispatch(slice.actions.clearAllChartAndTable);
}

export function clearTableData() {
  return dispatch(slice.actions.clearDataTable);
}

export function clearDailyChartData() {
  return dispatch(slice.actions.clearDailyChartData);
}

function initializeSelectData(projectsArr, urlParams, dailyInfoDetail) {
  let result = false;
  try {
    if (projectsArr != null && projectsArr.length > 0) {
      const findProj = urlParams?.projectId ? findDataById(projectsArr, urlParams.projectId) : projectsArr[0];
      const findSeason = urlParams?.seasonId ? findDataById(findProj.seasons, urlParams.seasonId) : findProj.seasons[0];
      const findLocation = dailyInfoDetail
        ? dailyInfoDetail.enumSamplingLocationDto
        : findSeason.samplingLocations[0] ?? { id: 0, name: '' };

      setSelectData({
        projects: findProj ?? { id: 0, name: '' },
        seasons: findSeason ?? { id: 0, name: '' },
        locations: findLocation,
        dailyInfo: {
          dailyInfoId: dailyInfoDetail ? dailyInfoDetail.id : 0,
          displayDay: dailyInfoDetail ? dailyInfoDetail.day : 0,
        },
      });
    }
    result = true;
  } catch (error) {
    console.log(error);
  }
  return result;
}

export function setIsAdmin(value) {
  isAdmin = value;
}

export function getAdmin() {
  return isAdmin;
}

// API Call functions
export function getProjects(urlParams, companyId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const API_PATH = getPathogenAPIPathByRole(getAdmin(), 'projects');
      const config = companyId ? { params: { companyId } } : null;
      const response = await axios.get(API_PATH, config);
      const { data, status } = response;
      let dailyInfoDetail = null;

      dispatch(slice.actions.setProject({ data, status }));

      if (urlParams && urlParams.dailyInfoId) {
        const { projectId, seasonId, dailyInfoId } = urlParams;
        const DAILYINFO_API_PATH = getPathogenAPIPathByRole(getAdmin(), 'dailyInfoItem');
        let dailyInfoResponse;

        if (getAdmin())
          dailyInfoResponse = await axios.get(DAILYINFO_API_PATH(projectId, seasonId, dailyInfoId), config);
        else dailyInfoResponse = await axios.get(DAILYINFO_API_PATH(projectId, seasonId, dailyInfoId));

        dailyInfoDetail = dailyInfoResponse.data;
      }

      if (!initializeSelectData(data.projects, urlParams, dailyInfoDetail)) {
        initializeSelectData(data.projects);
      }
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getSeasonChart(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const API_PATH = getPathogenAPIPathByRole(getAdmin(), 'seasonChart');
      const response = await axios.get(API_PATH, { params });
      const { data, status } = response;
      dispatch(slice.actions.setSeasonChart({ data, status, params }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getSameDaySeasonChart(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const API_PATH = getPathogenAPIPathByRole(getAdmin(), 'sameDaySeasonChart');
      const response = await axios.get(API_PATH, { params });
      const { data, status } = response;
      dispatch(slice.actions.setSeasonChart({ data, status, params }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getSeasonChartWidget(params) {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.pathogen.seasons.chart, {
        params,
      });
      const { data, status } = response;
      dispatch(slice.actions.setSeasonChartWidget({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getDailyChart(params) {
  return async () => {
    try {
      const API_PATH = getPathogenAPIPathByRole(getAdmin(), 'dailyChart');
      const response = await axios.get(API_PATH, { params });
      const { data, status } = response;
      dispatch(slice.actions.setDailyChart({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getSeasonTable(params) {
  return async () => {
    try {
      const API_PATH = getPathogenAPIPathByRole(getAdmin(), 'seasonTable');
      const response = await axios.get(API_PATH, { params });
      const { data, status } = response;
      dispatch(slice.actions.setSeasonTable({ data, status, params }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getMiniSeasonTable(params) {
  return async () => {
    try {
      const API_PATH = getPathogenAPIPathByRole(getAdmin(), 'miniSeasonTable');
      const response = await axios.get(API_PATH, { params });
      const { data, status } = response;
      dispatch(slice.actions.setSeasonTable({ data, status, isMini: true, params }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getSameDaySeasonTable(params) {
  return async () => {
    try {
      const API_PATH = getPathogenAPIPathByRole(getAdmin(), 'sameDaySeasonTable');
      const response = await axios.get(API_PATH, { params });
      const { data, status } = response;
      dispatch(slice.actions.setSeasonTable({ data, status, params }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getDailyTable(params) {
  return async () => {
    try {
      const API_PATH = getPathogenAPIPathByRole(getAdmin(), 'dailyTable');
      const response = await axios.get(API_PATH, { params });
      const { data, status } = response;
      dispatch(slice.actions.setDailyTable({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getMiniDailyTable(params) {
  return async () => {
    try {
      const API_PATH = getPathogenAPIPathByRole(getAdmin(), 'miniDailyTable');
      const response = await axios.get(API_PATH, { params });
      const { data, status } = response;
      dispatch(slice.actions.setDailyTable({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getDailyInfoTable(params) {
  return async () => {
    try {
      const API_PATH = getPathogenAPIPathByRole(getAdmin(), 'dailyInfoTable');
      const response = await axios.get(API_PATH, { params });
      const { data, status } = response;
      dispatch(slice.actions.setDailyInfoTable({ status, data }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getSamplingResultTable(projectId, seasonId, params) {
  return async () => {
    try {
      const API_PATH = getPathogenAPIPathByRole(getAdmin(), 'samplingResult');
      let response;

      if (isAdmin) response = await axios.get(API_PATH, { params });
      else response = await axios.get(API_PATH(projectId, seasonId));

      const { data, status } = response;
      dispatch(slice.actions.setSamplingResultTable({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getDailyInfoData(dailyInfoId, companyId) {
  return async () => {
    try {
      const API_PATH = getPathogenAPIPathByRole(getAdmin(), 'dailyInfoData');
      const config = companyId ? { params: { companyId } } : null;
      const response = await axios.get(API_PATH(dailyInfoId), config);
      const { data, status } = response;
      dispatch(slice.actions.setDailyInfo({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

// Sampling Edit - Daily Info Form: Response Status is separately managed
export function postDailyInfo(dailyInfoData) {
  return async () => {
    try {
      const response = await axios.post(PATH_SERVER_API.pathogen.daily.post, dailyInfoData);
      const { data, status } = response;
      dispatch(slice.actions.postDailyInfoSuccess({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

// sampling request
export function postSamplingRequest(formData) {
  return async () => {
    try {
      const response = await axios.post(PATH_SERVER_API.pathogen.samplingRequest, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      const { data, status } = response;
      dispatch(slice.actions.postSamplingRequestSuccess({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

// Widgets on MainDashboard
export function getWidgets(projectId, seasonId, locationId) {
  return async () => {
    try {
      const accountId = getUserIdFromToken(localStorage.getItem('accessToken'));
      if (accountId) {
        const response = await axios.get(PATH_SERVER_API.pathogen.widgets, {
          params: { accountId, projectId, seasonId, locationId },
        });
        const { data, status } = response;
        dispatch(slice.actions.setWidgets({ data, status }));
      } else {
        dispatch(slice.actions.hasError({ message: 'Cannot find AccountID' }));
      }
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function postWidget(reqData) {
  return async () => {
    try {
      const accountId = getUserIdFromToken(localStorage.getItem('accessToken'));
      if (accountId) {
        reqData.accountId = accountId;
        const response = await axios.post(PATH_SERVER_API.pathogen.widgets, reqData);
        const { data, status } = response;
        dispatch(slice.actions.setWidget({ data, status }));
      } else {
        dispatch(slice.actions.hasError({ message: 'Cannot find AccountID' }));
      }
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

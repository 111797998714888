import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { ADMIN_ERROR_MESSAGE } from '../../../const/common';
import { PATH_SERVER_API } from '../../../routes/paths';
import axios from '../../../utils/axios';

const initialState = {
  commonResponseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  languages: [],
};

const slice = createSlice({
  name: 'adminCommon',
  initialState,
  reducers: {
    hasError(state, action) {
      const { message, status } = action.payload;
      state.commonResponseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },

    clearResponseStatus(state) {
      state.commonResponseStatus = initialState.commonResponseStatus;
    },

    setLanguages(state, action) {
      const { status, data } = action.payload;
      state.commonResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };

      state.languages = data;
    },
  },
});

export default slice.reducer;

export function clearResponseStatusData() {
  return dispatch(slice.actions.clearResponseStatus);
}

export function getAllLanguages() {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.common.getAllLanguages);
      const { status, data } = response;
      dispatch(slice.actions.setLanguages({ status, data }));
    } catch (error) {
      const { status } = error.response;
      dispatch(slice.actions.hasError({ message: ADMIN_ERROR_MESSAGE[status] ?? error.message, status }));
    }
  };
}

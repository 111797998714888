import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import axios from '../../../utils/axios';
import { PATH_SERVER_API } from '../../../routes/paths';
import { ADMIN_ERROR_MESSAGE } from '../../../const/common';
import { replaceNullWithEmptyString } from '../../../utils/getData';

const initialState = {
  slideshowResponseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  formResponseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  // for list
  slideshow: {
    data: [],
  },
  // for Form
  slideshowInfoData: {
    id: 0,
    groups: 'RESOURCES',
    title: { id: 0, translations: [] },
    description: { id: 0, translations: [] },
    linkUrl: '',
    imageUrl: '',
    file: null,
    status: 'INACTIVE',
    orderNumber: 0,
  },
};

const slice = createSlice({
  name: 'adminSlideshow',
  initialState,
  reducers: {
    hasError(state, action) {
      const { message, status } = action.payload;
      state.slideshowResponseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },

    hasFormError(state, action) {
      const { message, status } = action.payload;
      state.formResponseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },

    clearResponseStatus(state) {
      state.slideshowResponseStatus = initialState.slideshowResponseStatus;
      state.formResponseStatus = initialState.formResponseStatus;
    },

    setDefaultTranslation(state, action) {
      // title and description in the form
      const { langArray } = action.payload;
      const title = { id: 0, translations: langArray };
      const description = { id: 0, translations: langArray };
      state.slideshowInfoData = {
        ...initialState.slideshowInfoData,
        title,
        description,
      };
    },

    setSlideShowList(state, action) {
      const { data, status } = action.payload;
      state.slideshowResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };
      state.slideshow = { data };
    },

    setSlideShowInfoData(state, action) {
      const { data, status } = action.payload;
      const convertedDataObj = replaceNullWithEmptyString(data);
      state.slideshowResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };
      state.slideshowInfoData = convertedDataObj;
    },

    postSlideShowData(state, action) {
      const { status, data } = action.payload;
      const { resultMsg, ...rest } = data;
      state.slideshowInfoData = rest;
      state.formResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
        message: resultMsg.message,
      };
    },
  },
});

export default slice.reducer;

export function clearResponseStatusData() {
  return dispatch(slice.actions.clearResponseStatus);
}

export function setDefaultTranslationValue(languages) {
  return async () => {
    if (languages.length <= 0) return;
    const langArray = [];
    languages.forEach((lang) => {
      const langObj = {
        id: 0,
        languageId: lang.languageId,
        languageName: lang.languageName,
        text: '',
      };
      langArray.push(langObj);
    });
    dispatch(slice.actions.setDefaultTranslation({ langArray }));
  };
}

export function getSlideShowList() {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.slideshow);
      const { data, status } = response;
      dispatch(slice.actions.setSlideShowList({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

export function getSlideShowById(id) {
  return async () => {
    try {
      const response = await axios.get(`${PATH_SERVER_API.admin.slideshow}/${id}`);
      const { data, status } = response;
      dispatch(slice.actions.setSlideShowInfoData({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

export function postSlideShow(formData) {
  return async () => {
    try {
      const response = await axios.post(PATH_SERVER_API.admin.slideshow, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      const { data, status } = response;
      dispatch(slice.actions.postSlideShowData({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

// @mui
import { enUS, esES } from '@mui/material/locale';
// routes
import { PATH_ADMIN, PATH_AUTH, PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = {
  DEFAULT: PATH_AUTH.login,
  USER: PATH_DASHBOARD.general.overview,
  ADMIN: PATH_ADMIN.companyManagement.company,
  UPLOADER: PATH_ADMIN.originalFileUpload.upload,
};

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
};

// CHART LEGEND MARKER SHAPES LIST
// ----------------------------------------------------------------------
export const PathogenGroupTypeList = [
  {
    type: '16S',
    shape: `<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.06 36.06" width="9px" height="9px" style="fill:##color##;">
              <g id="Layer_1-2">
                <rect class="cls-1" width="36.06" height="36.06" rx="5.15" ry="5.15"/>
              </g>
            </svg>`,
  },
  {
    type: '16S-Human',
    shape: `<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.79 37.57" width="10px" height="10px" style="fill:##color##;">
              <g id="Layer_2-2">
               <path class="cls-1" d="M42.02,29.14L26.19,2.7c-.78-1.28-2.01-2.18-3.46-2.54-1.46-.36-2.96-.13-4.24,.65-.77,.46-1.42,1.12-1.89,1.89h0L.75,29.16c-1.55,2.68-.63,6.11,2.05,7.66,.83,.48,1.78,.74,2.76,.75h31.68c3.09-.03,5.58-2.58,5.54-5.67-.01-.96-.27-1.91-.77-2.77Z"/>
               </g>
           </svg>`,
  },
  {
    type: '18S',
    shape: `<svg id="Layer_3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.05 40.05" width="10px" height="10px" style="fill:##color##;">
               <g id="Layer_3-2">
                 <rect class="cls-1" x="4.57" y="4.57" width="30.91" height="30.91" rx="4.41" ry="4.41" transform="translate(-8.29 20.02) rotate(-45)"/>
               </g>
            </svg>`,
  },
  {
    type: 'ITS',
    shape: `<svg id="Layer_4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.26 41.46" width="10px" height="10px" style="fill:##color##;">
              <g id="Layer_4-2">
                <path class="cls-1" d="M41.72,14.59L24.09,.85c-1.45-1.13-3.47-1.13-4.92,0L1.54,14.59C.18,15.65-.35,17.46,.23,19.09l7.04,19.72c.57,1.59,2.08,2.65,3.77,2.65h21.18c1.69,0,3.2-1.07,3.77-2.65l7.04-19.72c.58-1.63,.06-3.44-1.31-4.5Z"/>
               </g>
            </svg>`,
  },
  {
    type: 'OTHERS',
    shape: `<svg id="Layer_5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.2 36.2" width="10px" height="10px" style="fill:##color##;">
             <g id="Layer_5-2">
               <circle class="cls-1" cx="18.1" cy="18.1" r="18.1"/>
              </g>
            </svg>`,
  },
  {
    type: 'NO-TYPE',
    shape: '',
  },
];

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_ca.svg',
  },
  {
    label: 'Spanish',
    value: 'es',
    systemValue: esES,
    icon: '/assets/icons/flags/ic_flag_es.svg',
  },
];

export const defaultLang = allLangs[0]; // English

export const EXCEPTION = {
  AccessTokenExpired: 460,
  RefreshTokenExpired: 461,
  TokenUserNotFound: 468,
  CompanyNotFound: 469,
};

import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';
import { getInfoFromToken } from '../utils/jwt';
import { PATH_AFTER_LOGIN } from '../config';

PublicGuard.propTypes = {
  children: PropTypes.node,
};
export default function PublicGuard({ children }) {
  const { isValidAuthenticated, isInitialized, otpEnabled, otpVerified, multiCompany, user } = useAuth();
  const currentRole = user?.role;
  const companyId = getInfoFromToken('companyId');
  const isNoCompany = currentRole === 'USER' && !multiCompany && companyId === 'null';
  const isValidTwoFactor = otpVerified || otpVerified === otpEnabled;
  const isValidSelectOperation = isNoCompany ? false : !multiCompany || (multiCompany && companyId !== 'null');

  const isFullyAuthenticated = () => isValidAuthenticated() && isValidTwoFactor && isValidSelectOperation;

  if (!isInitialized) return <LoadingScreen />;

  if (isFullyAuthenticated()) return <Navigate to={PATH_AFTER_LOGIN[currentRole]} />;

  return <>{children}</>;
}

const ERROR_MESSAGE = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  467: 'System setting error',
  undefined: 'An unexpected error occurred. Please try again later.',
};

const ADMIN_ERROR_MESSAGE = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  464: 'Pathogen Duplicated',
  465: 'Header Not Validated',
  466: 'Not found pathogen group code',
  467: 'System setting error',
  undefined: 'An unexpected error occurred. Please try again later.',
};

const DEFAULT_HEADER_HEIGHT = 105;

export { ERROR_MESSAGE, ADMIN_ERROR_MESSAGE, DEFAULT_HEADER_HEIGHT };

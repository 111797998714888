import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { PATH_SERVER_API } from '../../routes/paths';
import axios from '../../utils/axios';
import { ERROR_MESSAGE } from '../../const/common';

const initialState = {
  isLoading: false,
  slideShowResources: null,
  responseStatus: { message: '', status: null, isSuccess: false, isSet: false },
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    hasError(state, action) {
      state.isLoading = false;
      const { message, status } = action.payload;
      state.responseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },
    startLoading(state) {
      state.isLoading = true;
    },
    clearResponseStatus(state) {
      state.responseStatus = initialState.responseStatus;
    },
    setSlideShowResources(state, action) {
      const { data, status } = action.payload;
      state.responseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };
      state.isLoading = false;
      state.slideShowResources = data;
    },
  },
});

// Reducer
export default slice.reducer;

// Change state
export function clearResponseStatusData() {
  return dispatch(slice.actions.clearResponseStatus);
}

// Api Call Functions
export function getSlideShowResources() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(PATH_SERVER_API.dashboard.slideShow);
      const { data, status } = response;
      dispatch(slice.actions.setSlideShowResources({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { PATH_SERVER_API } from '../../../routes/paths';
import axios from '../../../utils/axios';
import { ADMIN_ERROR_MESSAGE } from '../../../const/common';

const initialState = {
  announcementResponseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  formResponseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  announcementList: {
    data: [],
  },
  announcementInfoData: {
    id: 0,
    title: { id: 0, translations: [] },
    description: { id: 0, translations: [] },
    startDate: '',
    endDate: '',
    active: false,
    typeStr: '',
  },
};

const slice = createSlice({
  name: 'adminAnnouncement',
  initialState,
  reducers: {
    hasError(state, action) {
      const { message, status } = action.payload;
      state.announcementResponseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },

    hasFormError(state, action) {
      const { message, status } = action.payload;
      state.formResponseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },

    clearResponseStatus(state) {
      state.announcementResponseStatus = initialState.announcementResponseStatus;
      state.formResponseStatus = initialState.formResponseStatus;
    },

    setAllAnnouncements(state, action) {
      const { data, status } = action.payload;
      state.announcementResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };
      state.announcementList = { data };
    },

    setAnnouncementInfoData(state, action) {
      // To handle Null values
      Object.entries(action.payload).forEach((el) => {
        action.payload = {
          ...action.payload,
          [el[0]]: el[1] !== null ? el[1] : '',
        };
      });
      state.announcementInfoData = action.payload;
    },

    setCreateAndUpdateAnnouncement(state, action) {
      const { status, data } = action.payload;
      const { resultMsg, ...rest } = data;
      state.announcementInfoData = rest;
      state.formResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
        message: resultMsg.message,
      };
    },
  },
});

export default slice.reducer;

export function clearResponseStatusData() {
  return dispatch(slice.actions.clearResponseStatus);
}

export function getAllAnnouncements() {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.announcement.getAllAnnouncements);
      const { data, status } = response;
      dispatch(slice.actions.setAllAnnouncements({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getAnnouncement(announcementId) {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.announcement.getAnnouncement(announcementId));
      dispatch(slice.actions.setAnnouncementInfoData(response.data));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function createAndUpdateAnnouncement(formData) {
  return async () => {
    try {
      const response = await axios.post(PATH_SERVER_API.admin.announcement.createAndUpdateAnnouncement, formData);
      const { data, status } = response;
      dispatch(slice.actions.setCreateAndUpdateAnnouncement({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

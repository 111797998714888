import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { PATH_SERVER_API } from '../../../routes/paths';
import axios from '../../../utils/axios';
import { ADMIN_ERROR_MESSAGE } from '../../../const/common';

const initialState = {
  pathogenUrlResponseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  formResponseStatus: { message: '', status: null, isSuccess: false, isSet: false },
  pathogensUrlListData: {
    data: [],
  },
  pathogenInfoData: {
    id: 0,
    friendlyName: '',
    name: '',
    linkUrl: '',
  },
};

const slice = createSlice({
  name: 'adminPathogensUrl',
  initialState,
  reducers: {
    hasError(state, action) {
      state.isLoading = false;
      const { message, status } = action.payload;
      state.pathogenUrlResponseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },
    hasFormError(state, action) {
      const { message, status } = action.payload;
      state.formResponseStatus = {
        message,
        status,
        isSuccess: false,
        isSet: true,
      };
    },
    clearResponseStatus(state) {
      state.pathogenUrlResponseStatus = initialState.pathogenUrlResponseStatus;
      state.formResponseStatus = initialState.formResponseStatus;
    },

    setAllPathogensUrl(state, action) {
      const { data, status } = action.payload;
      state.pathogenUrlResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
      };
      state.pathogensUrlListData = { data };
    },
    setPathogenUrlData(state, action) {
      // To handle Null values
      Object.entries(action.payload).forEach((el) => {
        action.payload = {
          ...action.payload,
          [el[0]]: el[1] !== null ? el[1] : '',
        };
      });
      state.pathogenInfoData = action.payload;
    },
    setEditPathogenUrl(state, action) {
      const { status, data } = action.payload;
      state.formResponseStatus = {
        status,
        isSuccess: true,
        isSet: true,
        message: data.message,
      };
    },
  },
});

export default slice.reducer;

export function clearResponseStatusData() {
  return dispatch(slice.actions.clearResponseStatus);
}

export function getAllPathogensUrlList() {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.pathogensUrl.getAllPathogensUrlList);
      const { data, status } = response;
      dispatch(slice.actions.setAllPathogensUrl({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function getPathogensUrl(pathogenId) {
  return async () => {
    try {
      const response = await axios.get(PATH_SERVER_API.admin.pathogensUrl.getPathogensUrl(pathogenId));
      dispatch(slice.actions.setPathogenUrlData(response.data));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasError({ message, status: error.response?.status }));
    }
  };
}

export function editPathogensUrl(formData) {
  return async () => {
    try {
      const response = await axios.post(PATH_SERVER_API.admin.pathogensUrl.editPathogensUrl, formData);
      const { data, status } = response;
      dispatch(slice.actions.setEditPathogenUrl({ data, status }));
    } catch (error) {
      const message = error.response?.data.message ?? ADMIN_ERROR_MESSAGE[error.response?.status];
      dispatch(slice.actions.hasFormError({ message, status: error.response?.status }));
    }
  };
}

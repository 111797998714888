import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';
import { getInfoFromToken } from '../utils/jwt';
import { PATH_AUTH, PATH_PAGE } from '../routes/paths';
import { PATH_AFTER_LOGIN } from '../config';

AuthGuard.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string), // Example ['USER', 'ADMIN']
  children: PropTypes.node,
};

export default function AuthGuard({ roles, children }) {
  const { isInitialized, isValidAuthenticated, otpEnabled, otpVerified, multiCompany, user } = useAuth();
  const { pathname } = useLocation();
  const currentRole = user?.role;

  const companyId = getInfoFromToken('companyId');
  const isNoCompany = currentRole === 'USER' && !multiCompany && companyId === 'null';
  const isValidTwoFactor = otpVerified || otpVerified === otpEnabled;
  const isValidSelectOperation = !multiCompany || (multiCompany && companyId !== 'null');

  const isRoleUnauthorized = typeof roles !== 'undefined' && !roles.includes(currentRole);
  const isTwoFactorInvalid = !isValidTwoFactor && pathname !== PATH_AUTH.verify;

  const isSelectOperationRequired =
    isValidTwoFactor && (!isValidSelectOperation || isNoCompany) && pathname !== PATH_AUTH.selectOperation;

  const isSelectOperationCompleted =
    isValidTwoFactor &&
    isValidSelectOperation &&
    !isNoCompany &&
    [PATH_AUTH.verify, PATH_AUTH.selectOperation].includes(pathname);

  if (!isInitialized) return <LoadingScreen />;

  if (!isValidAuthenticated()) return <Navigate to={PATH_AUTH.login} />;

  if (isRoleUnauthorized) return <Navigate to={PATH_PAGE.page403} />;

  if (isTwoFactorInvalid) return <Navigate to={PATH_AUTH.verify} />;

  if (isSelectOperationRequired) return <Navigate to={PATH_AUTH.selectOperation} />;

  if (isSelectOperationCompleted) return <Navigate to={PATH_AFTER_LOGIN[currentRole]} />;

  return <>{children}</>;
}

import { PATH_ADMIN, PATH_SAMPLING } from '../routes/paths';

export function linkUrl(isMini, isAdmin, companyId, projectId, seasonId, chartLabels, dailyInfoIds, text) {
  const isLinkVal = isMini === undefined && projectId !== null && seasonId !== null && dailyInfoIds !== null;
  if (!isLinkVal) {
    return null;
  }
  if (isAdmin && companyId !== null && dailyInfoIds) {
    const index = dailyInfoIds.findIndex((val) => String(val) === String(text));
    const dailyInfoId = chartLabels[index];
    return PATH_ADMIN.companyManagement.samplingDetail(companyId, projectId, seasonId, dailyInfoId);
  }
  if (!isAdmin && dailyInfoIds) {
    const index = dailyInfoIds.findIndex((val) => String(val) === String(text));
    const dailyInfoId = chartLabels[index];
    return PATH_SAMPLING.general.edit(projectId, seasonId, dailyInfoId);
  }
  return null;
}

export function chartLabel(chartLabels, dailyInfoIds, text) {
  if (dailyInfoIds) {
    const number = dailyInfoIds.findIndex((val) => String(val) === String(text));
    const chartLabel = chartLabels[number];
    return `${chartLabel}`;
  }
  return text;
}

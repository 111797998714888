import { Alert } from '@mui/material';
import PropTypes from 'prop-types';

AnnouncementAlert.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  sx: PropTypes.object,
};
export default function AnnouncementAlert({ type, title, desc, sx }) {
  return (
    <Alert
      severity={type === 'Alert' ? 'warning' : 'info'}
      sx={{
        '&.MuiSvgIcon-root, .MuiSvgIcon-fontSizeSmall': {
          width: '15px',
          height: '15px',
        },
        '&.MuiButtonBase-root, .MuiIconButton-root': {
          padding: '8px',
        },
        ...sx,
      }}
    >
      <span data-cy="announcement">
        {title ? (
          <span>
            <strong>{title} - </strong>
          </span>
        ) : null}
        {desc}
      </span>
    </Alert>
  );
}

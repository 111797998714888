import jwtDecode from 'jwt-decode';

// ----------------------------------------------------------------------

const getOtpVerifiedInfo = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  return decoded.otpVerified === 'true';
};

const getUserIdFromToken = (accessToken) => {
  if (!accessToken) return null;

  const decoded = jwtDecode(accessToken);
  return decoded.userId;
};

const getInfoFromToken = (param) => {
  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) return null;

  const decoded = jwtDecode(accessToken);
  return decoded[param];
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  } else {
    localStorage.removeItem('accessToken');
  }
};

export { setSession, getOtpVerifiedInfo, getUserIdFromToken, getInfoFromToken };

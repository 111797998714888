import axios from 'axios';
import { EXCEPTION, HOST_API } from '../config';
import { setSession } from './jwt';
import { PATH_AUTH, PATH_SERVER_API, PUBLIC_REQUEST_URL } from '../routes/paths';

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const isPublicRequest = PUBLIC_REQUEST_URL.includes(config.url);
    const token = localStorage.getItem('accessToken');
    if (token && !isPublicRequest) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const langStorage = localStorage.getItem('i18nextLng');
    if (langStorage) {
      config.headers['Accept-Language'] = langStorage;
    }

    config.headers.PageUrl = window.location.href;

    return config;
  },
  (error) => error
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }

    try {
      if (error.response.status === EXCEPTION.AccessTokenExpired) {
        const refreshResponse = await axiosInstance.post(PATH_SERVER_API.auth.refresh);
        const { accessToken } = refreshResponse.data;
        setSession(accessToken);

        return axiosInstance(error.config);
      }

      if (error.response.status === EXCEPTION.RefreshTokenExpired) {
        window.location.href = PATH_AUTH.login;
      }

      if (error.response.status === EXCEPTION.TokenUserNotFound) {
        window.location.href = PATH_AUTH.login;
      }

      if (error.response.status === EXCEPTION.CompanyNotFound) {
        setSession(error.response.data);
        window.history.pushState(null, null, PATH_AUTH.selectOperation);
        window.history.back();
      }
    } catch (_error) {
      return Promise.reject((error.response && error.response.data) || 'Something went wrong');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

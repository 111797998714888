import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';
import LogoImg from '../assets/logo.png';
import MobileLogo from '../assets/mobile-logo.png';
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

export default function Logo() {
  const smUp = useResponsive('up', 'sm');

  const logo = (
    <Box data-cy="logo" style={{ display: 'inline-block'}}>
      {smUp && <img src={LogoImg} alt="logo" style={{ width: '136px', height: '57px' }} />}
      {!smUp && <img src={MobileLogo} alt="logo" style={{ width: '35px', height: '40px' }} />}
    </Box>
  );

  return <RouterLink to="/">{logo}</RouterLink>;
}

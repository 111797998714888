import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import dashboardReducer from './slices/dashboard';
import pathogenReducer from './slices/pathogen';
import accountReducer from './slices/account';
import savedReducer from './slices/saved';
import adminAccountReducer from './slices/admin/adminAccount';
import adminPathogenReducer from './slices/admin/adminPathogen';
import companyAccountReducer from './slices/admin/companyAccount';
import adminPathogensUrlReducer from './slices/admin/adminPathogensUrl';
import adminCommonReducer from './slices/admin/adminCommon';
import adminSlideshowReducer from './slices/admin/adminSlideshow';
import adminAnnouncementReducer from './slices/admin/adminAnnouncement';
import adminUploaderReducer from './slices/admin/adminUploader';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const savePersistConfig = {
  key: 'saved',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['savedSelected'],
};

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  pathogen: pathogenReducer,
  account: accountReducer,
  saved: persistReducer(savePersistConfig, savedReducer),
  adminAccount: adminAccountReducer,
  adminPathogen: adminPathogenReducer,
  companyAccount: companyAccountReducer,
  adminPathogensUrl: adminPathogensUrlReducer,
  adminCommon: adminCommonReducer,
  adminSlideshow: adminSlideshowReducer,
  adminAnnouncement: adminAnnouncementReducer,
  adminUploader: adminUploaderReducer,
});

export { rootPersistConfig, rootReducer };

const findDataById = (arr, strId) => arr.find((v) => v.id === Number(strId));
const findDataByKey = (arr, strKey, value) => arr.find((v) => v[strKey] === value);

/**
 * Throw Error if it is not valid
 * @param params array
 */
const checkUrlParameter = (params) => {
  if (!params) return;

  params.forEach((element) => {
    if (Number.isNaN(Number(element))) throw Error('Invalid Parameter');
  });
};

const findFilteredListByKey = (arr, keyword) => {
  let filteredList = arr.map((el) => el[keyword]);
  // filter as unique elements
  filteredList = filteredList.filter((item, index) => filteredList.indexOf(item) === index);
  return filteredList;
};

const findFilteredListByKeyOrderByAlphabet = (arr, keyword) => {
  let filteredList = arr.map((el) => el[keyword]);
  // filter as unique elements
  filteredList = filteredList.filter((item, index) => filteredList.indexOf(item) === index);
  return filteredList.sort((a, b) => a.localeCompare(b));
};

const replaceNullWithEmptyString = (obj) => {
  Object.entries(obj).forEach((el) => {
    obj = {
      ...obj,
      [el[0]]: el[1] !== null ? el[1] : '',
    };
  });
  return obj;
};

export {
  findDataById,
  findDataByKey,
  checkUrlParameter,
  findFilteredListByKey,
  findFilteredListByKeyOrderByAlphabet,
  replaceNullWithEmptyString,
};

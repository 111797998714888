import { Suspense, lazy, useEffect } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';
import { PATH_AUTH, ROOT_URL_LIST } from './paths';
import PublicGuard from '../guards/PublicGuard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={
        <LoadingScreen
          isDashboard={
            pathname.includes('/dashboard') || pathname.includes('/pathogens') || pathname.includes('/sampling')
          }
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { pathname } = useLocation();
  const { getCommonInfo } = useAuth();
  const routes = useRoutes([
    {
      path: '/',
      children: [
        {
          path: '/',
          element: (
            <PublicGuard>
              <Login />
            </PublicGuard>
          ),
        },
        {
          path: 'reset-password',
          element: (
            <PublicGuard>
              <ResetPassword />
            </PublicGuard>
          ),
        },
        {
          path: 'invitation',
          element: (
            <PublicGuard>
              <NewPassword />
            </PublicGuard>
          ),
        },
        {
          path: 'new-password',
          element: (
            <PublicGuard>
              <NewPassword />
            </PublicGuard>
          ),
        },
        {
          path: 'verify',
          element: (
            <AuthGuard roles={['USER', 'ADMIN', 'UPLOADER']}>
              <VerifyCode />
            </AuthGuard>
          ),
        },
        {
          path: 'select-operation',
          element: (
            <AuthGuard roles={['USER']}>
              <SelectOperation />
            </AuthGuard>
          ),
        },
      ],
    },

    // Admin Routes
    {
      path: 'admin',
      element: (
        <AuthGuard roles={['ADMIN']}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={'/admin/company-management'} replace />, index: true },

        {
          path: 'company-management',
          children: [
            { element: <Navigate to={'/admin/company-management/overview'} replace />, index: true },
            { path: 'overview', element: <CompanyManagement /> },
            { path: 'company/:companyId/user-management', element: <CompanyUserManagement /> },
            { path: 'new-company', element: <EditCompany /> },
            { path: 'company/:companyId/edit-company', element: <EditCompany /> },
            { path: 'company/:companyId/projects', element: <UserProjects /> },
            { path: 'company/:companyId/new-project', element: <UserEditProject /> },
            { path: 'company/:companyId/projects/:projectId/edit-project', element: <UserEditProject /> },
            { path: 'company/:companyId/projects/:projectId/seasons', element: <UserSeasons /> },
            { path: 'company/:companyId/projects/:projectId/new-season', element: <UserEditSeasons /> },
            {
              path: 'company/:companyId/projects/:projectId/seasons/:seasonId/edit-season',
              element: <UserEditSeasons />,
            },
            { path: 'company/:companyId/projects/:projectId/seasons/:seasonId/import/:type', element: <ImportData /> },
            { path: 'pathogens-detail/company/:companyId', element: <UserPathogensDetail /> },
            {
              path: 'pathogens-detail/company/:companyId/projects/:projectId/seasons/:seasonId/html/:id',
              element: <PathogensDetailHtmlReport />,
            },
            {
              path: 'pathogens-detail/company/:companyId/sampling/projects/:projectId/seasons/:seasonId/dailyInfo/:dailyInfoId',
              element: <UserSamplingDetail />,
            },
            { path: 'company/:companyId/projects/:projectId/seasons/:seasonId/pdf-html', element: <PdfHtml /> },
          ],
        },

        {
          path: 'user-management',
          children: [
            { element: <Navigate to={'/admin/user-management/overview'} replace />, index: true },
            { path: 'overview', element: <UserManagement /> },
            { path: 'new-user', element: <EditUser /> },
            { path: 'accounts/:accountId/edit-user', element: <EditUser /> },
          ],
        },

        {
          path: 'pathogen-management',
          children: [
            { element: <Navigate to={'/admin/pathogen-management/overview'} replace />, index: true },
            { path: 'overview', element: <PathogenManagement /> },
            { path: 'pathogens/:pathogenId/edit-url', element: <EditPathogensUrl /> },
          ],
        },

        {
          path: 'announcement-management',
          children: [
            { element: <Navigate to={'/admin/announcement-management/overview'} replace />, index: true },
            { path: 'overview', element: <AnnouncementManagement /> },
            { path: 'new', element: <EditAnnouncement /> },
            { path: ':announcementId/edit', element: <EditAnnouncement /> },
          ],
        },

        {
          path: 'slideshow-management',
          children: [
            { element: <Navigate to={'/admin/slideshow-management/overview'} replace />, index: true },
            { path: 'overview', element: <SlideShowManagement /> },
            { path: 'new', element: <EditSlideShow /> },
            { path: ':slideshowId/edit', element: <EditSlideShow /> },
          ],
        },
      ],
    },

    // Uploader
    {
      path: 'admin',
      element: (
        <AuthGuard roles={['UPLOADER']}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={'/admin/original-file/upload'} replace />, index: true },

        {
          path: 'original-file',
          children: [
            { element: <Navigate to={'/admin/original-file/upload'} replace />, index: true },
            { path: 'upload', element: <OriginalFileUpload /> },
          ],
        },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard roles={['USER']}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN.DEFAULT} replace />, index: true },
        { path: 'overview', element: <MainDashboard /> },
      ],
    },

    // User
    {
      path: 'user',
      element: (
        <AuthGuard roles={['USER', 'ADMIN', 'UPLOADER']}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/user/account" replace />, index: true },
        { path: 'account', element: <UserAccount /> },
      ],
    },

    /**
     * To leave left navbar, The 'RoleBaseGuard' added to each component
     */
    // Pathogen
    {
      path: 'pathogens',
      element: (
        <AuthGuard roles={['USER']}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/pathogens/overview" replace />, index: true },
        { path: 'overview', element: <Pathogens /> },
        { path: 'projects/:projectId/seasons/:seasonId', element: <PathogensDetail /> },
        { path: 'projects/:projectId/seasons/:seasonId/html/:id', element: <PathogensDetailHtmlReport /> },
      ],
    },
    // Sampling
    {
      path: 'sampling',
      element: (
        <AuthGuard roles={['USER']}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/sampling/overview" replace />, index: true },
        { path: 'overview', element: <Sampling /> },
        { path: 'projects/:projectId/seasons/:seasonId', element: <Sampling /> },
        { path: 'projects/:projectId/seasons/:seasonId/dailyInfo/:dailyInfoId', element: <SamplingEdit /> },
      ],
    },
    // Submit Sample
    {
      path: 'submit-sample',
      element: (
        <AuthGuard roles={['USER']}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={'/submit-sample/overview'} replace />, index: true },
        { path: 'overview', element: <SubmitSample /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout isErrorPage />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);

  useEffect(() => {
    try {
      if (!ROOT_URL_LIST.includes(pathname)) {
        const isAuthUrl = Object.values(PATH_AUTH).includes(pathname);
        getCommonInfo(isAuthUrl);
      }
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return routes;
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const SelectOperation = Loadable(lazy(() => import('../pages/auth/SelectOperation')));

// ADMIN
const CompanyManagement = Loadable(lazy(() => import('../pages/admin/company-management/company/CompanyManagement')));
const CompanyUserManagement = Loadable(
  lazy(() => import('../pages/admin/company-management/company-user/CompanyUserManagement'))
);
const EditCompany = Loadable(lazy(() => import('../pages/admin/company-management/company/EditCompany')));

const UserManagement = Loadable(lazy(() => import('../pages/admin/user-management/UserManagement')));
const EditUser = Loadable(lazy(() => import('../pages/admin/user-management/EditUser')));
const UserProjects = Loadable(lazy(() => import('../pages/admin/company-management/project/UserProjects')));
const UserEditProject = Loadable(lazy(() => import('../pages/admin/company-management/project/UserEditProject')));
const UserSeasons = Loadable(lazy(() => import('../pages/admin/company-management/season/UserSeasons')));
const UserEditSeasons = Loadable(lazy(() => import('../pages/admin/company-management/season/UserEditSeasons')));
const ImportData = Loadable(lazy(() => import('../pages/admin/company-management/import/ImportData')));
const UserPathogensDetail = Loadable(
  lazy(() => import('../pages/admin/company-management/pathogen-detail/UserPathogensDetail'))
);
const UserSamplingDetail = Loadable(
  lazy(() => import('../pages/admin/company-management/pathogen-detail/UserSamplingDetail'))
);
const PdfHtml = Loadable(lazy(() => import('../pages/admin/company-management/pdf-html/PdfHtml')));

// ADMIN - Pathogen Management
const PathogenManagement = Loadable(lazy(() => import('../pages/admin/pathogen-management/PathogenManagement')));
const EditPathogensUrl = Loadable(lazy(() => import('../pages/admin/pathogen-management/EditPathogensUrl')));

const AnnouncementManagement = Loadable(
  lazy(() => import('../pages/admin/announcement-management/AnnouncementManagement'))
);
const EditAnnouncement = Loadable(lazy(() => import('../pages/admin/announcement-management/EditAnnouncement')));

// ADMIN - SlideShow Management
const SlideShowManagement = Loadable(lazy(() => import('../pages/admin/slideshow-management/SlideShowManagement')));
const EditSlideShow = Loadable(lazy(() => import('../pages/admin/slideshow-management/EditSlideShow')));

// ADMIN - UPLOADER
const OriginalFileUpload = Loadable(lazy(() => import('../pages/admin/original-file-upload/OriginalFileUpload')));

// DASHBOARD
const MainDashboard = Loadable(lazy(() => import('../pages/dashboard/MainDashboard')));

// PATHOGENS
const Pathogens = Loadable(lazy(() => import('../pages/pathogens/Pathogens')));
const PathogensDetail = Loadable(lazy(() => import('../pages/pathogens/PathogensDetail')));
const PathogensDetailHtmlReport = Loadable(lazy(() => import('../pages/pathogens/PathogensDetailHtmlReport')));

// SAMPLING
const Sampling = Loadable(lazy(() => import('../pages/sampling/Sampling')));
const SamplingEdit = Loadable(lazy(() => import('../pages/sampling/SamplingEdit')));

// SUBMIT SAMPLE
const SubmitSample = Loadable(lazy(() => import('../pages/submit-sample/SubmitSample')));

// USER
const UserAccount = Loadable(lazy(() => import('../pages/account/UserAccount')));

// MAIN
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
// components
import Logo from '../components/Logo';
import AnnouncementAlert from '../components/AnnouncementAlert';
import useAuth from '../hooks/useAuth';

const HeaderStyle = styled('header')(() => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
}));

LogoOnlyLayout.propTypes = {
  isErrorPage: PropTypes.bool,
};

export default function LogoOnlyLayout({ isErrorPage }) {
  const { announcement } = useAuth();
  return (
    <>
      <HeaderStyle>
        <Stack sx={{ width: '100%' }}>
          {!isErrorPage &&
            announcement?.map((el, index) => (
              <div style={{ padding: '0.5rem', paddingBottom: 0 }} key={index}>
                <AnnouncementAlert
                  sx={{ width: '100%' }}
                  type={announcement[index].typeStr}
                  title={el.title}
                  desc={el.description}
                />
              </div>
            ))}
          <div style={{ padding: '1.5rem' }}>
            <Logo />
          </div>
        </Stack>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
